import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'src/context/ThemeContext';
import { Kid } from 'src/model/AccountUser';

type Props = {
  kids: Kid[];
  onEdit: (id: string) => () => void;
};

const Table = ({ kids, onEdit }: Props) => {
  const { t } = useTranslation();
  const { IcEdit } = useContext(ThemeContext).image;

  return (
    <div className="text-sm leading-[1.5]">
      <div className="flex border-0 !border-b border-solid border-light-200 pb-4 font-bold text-grey-700 dark:border-dark-700">
        <div className="w-1/2 pr-3">{t('agency.desc.kid')}</div>
        <div className="w-1/2 pl-3">{t('agency.desc.commission')}</div>
      </div>
      {kids.map((v) => (
        <div
          key={v.id}
          className="relative flex border-0 !border-b border-solid border-light-200 py-4 dark:border-dark-700"
        >
          <div className="w-1/2 pr-3">{v.email}</div>
          <div className="flex w-1/2 flex-col gap-[10px] pl-3">
            {v.commissionDeltas?.cny !== undefined && <div>{`CNY: ${v.commissionDeltas.cny}`}</div>}
            {v.commissionDeltas?.vnd !== undefined && <div>{`VND: ${v.commissionDeltas.vnd}`}</div>}
          </div>
          <img
            src={IcEdit}
            className="absolute bottom-4 right-3 h-[21px] w-[21px] cursor-pointer"
            onClick={onEdit(v.id)}
          />
        </div>
      ))}
    </div>
  );
};

export default Table;
