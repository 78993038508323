import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import MenuTrading from 'src/component/MenuTrading';
import Pagination from 'src/component/Pagination';
import RoundSelect from 'src/component/RoundSelect';
import SelectOption from 'src/component/SelectOption';
import Tabs from 'src/component/Tabs';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { usePagination } from 'src/hook/usePagination';
import IcArrowDown from 'src/image/ic-arrow-down-s.svg';
import { OpenOrder } from 'src/model/Order';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getOpenOrders } from 'src/service/orderService';
import TradeCard from './component/TradeCard';
import TradeTable from './component/TradeTable';

const TradeAsk = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fiat } = useSelector((rootState: RootState) => rootState.coin);
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const { IcEmpty } = useContext(ThemeContext).image;
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openOrders, setOpenOrders] = useState<OpenOrder[]>();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { limit, offset, setCount, paginationProps } = usePagination();
  const tradingRef = useRef<HTMLDivElement>(null);
  const [isTradablefiats, setIsTradablefiats] = useState<string[]>();
  const [satatus, setSatatus] = useState<string>('all');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let quote = undefined;
    if (satatus !== 'all') quote = satatus;

    setIsLoading(true);
    getOpenOrders({ quote, offset, limit })
      .then((res) => {
        setOpenOrders(res.data);
        setCount(res.count);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  }, [refresh, limit, offset]);

  useEffect(() => {
    if (fiat.length < 0) return;
    const fiats = ['all'];
    fiat
      .filter((v) => v.isTradable)
      .map((v) => {
        fiats.push(v.id);
      });
    setIsTradablefiats(fiats);
  }, [fiat]);

  const onStatusFilterChange = (v: string) => {
    setSatatus(v);
    paginationProps.setPage(1);
    setRefresh(!refresh);
  };

  const onNavigate = (path: Page) => () => {
    navigate(path);
  };

  if (!isTradablefiats || isLoading || isTradablefiats?.length === 0) return <></>;

  return (
    <div>
      <div className="mb-[10px]">
        <BackButton />
      </div>
      <div className="mt-[10px] flex flex-row justify-between text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('trade.heading')}
        <div className="flex h-fit flex-row gap-[12px]">
          <Button appearance="text" onClick={onNavigate(Page.MyTrade)}>
            {t('trade.act.myTrade')}
          </Button>
          <div
            className="flex cursor-pointer items-center"
            onClick={() => setMenuOpen(true)}
            ref={tradingRef}
          >
            <div className="text-[14px] text-turquoise hover:underline dark:text-aqua">
              {t('trade.act.trading')}
            </div>
            <img src={IcArrowDown} />
          </div>
        </div>
      </div>
      <div className="mt-[10px] flex flex-row justify-between sm:mt-[20px]">
        <Tabs labels={['USDT']} />
        <div className="flex h-fit flex-row gap-[12px]">
          <Button appearance="outlined" onClick={() => setRefresh(!refresh)}>
            {t('trade.act.refresh')}
          </Button>
        </div>
      </div>

      <div className="mt-[15px] rounded-[4px] bg-white px-[15px] py-[25px] text-[14px] dark:bg-black-800 md:px-[40px] md:py-[25px]">
        <div className="flex gap-2">
          <RoundSelect value={satatus} onChange={onStatusFilterChange}>
            {isTradablefiats.map((v) => (
              <SelectOption value={v} key={v}>
                {v === 'all' ? t('trade.desc.all') : v.toUpperCase()}
              </SelectOption>
            ))}
          </RoundSelect>
        </div>
        {openOrders?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {openOrders &&
          openOrders.length > 0 &&
          (isBiggerThanMd ? <TradeTable data={openOrders} /> : <TradeCard data={openOrders} />)}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
      <MenuTrading
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={tradingRef.current}
      />
    </div>
  );
};

export default TradeAsk;
