import { useTranslation } from 'react-i18next';
import { OpenOrder } from 'src/model/Order';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: OpenOrder[];
};

const TradeCard = ({ data }: Props) => {
  const { t } = useTranslation();

  const elementMapFunction = (transaction: OpenOrder) => (
    <div key={transaction.id}>
      <div className="flex flex-col gap-[4px] pt-[15px]">
        <div className="text-[14px] font-bold text-turquoise dark:text-aqua">
          {transaction.userSlug}
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('trade.desc.amount')}
          </div>
          <div className="text-[14px]">{`${bnFormat(
            transaction.amount,
          )} ${transaction.base.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('trade.desc.paymentLimit')}
          </div>
          <div className="text-[14px]">
            {`${bnFormat(transaction.minTotal)} - ${bnFormat(
              transaction.maxTotal,
            )} ${transaction.quote.toUpperCase()}`}
          </div>
        </div>
        <div className="text-[12px] text-green dark:text-lime">
          {transaction.paymentMethodType === 'bank'
            ? t('trade.desc.bankCard')
            : t('bankAccount.desc.paymentType.' + transaction.paymentMethodType)}
        </div>
      </div>
      <div className="mt-[13px] h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default TradeCard;
