import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from 'src/component/Alert';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import { Severity } from 'src/constant/Notification';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { BankAccount } from 'src/model/Bank';
import { OneTradingForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { initTrading } from 'src/service/orderService';
import { bn, bnFormat } from 'src/util/bigNumber';
import TradingCard from './component/TradingCard';

const TradingBatch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { IcAddSmall: IcAdd } = useContext(ThemeContext).image;
  const { crypto, fiat } = useSelector((rootState: RootState) => rootState.coin);
  const location = useLocation();
  const stateTradingForm = location.state as OneTradingForm[] | null;
  const [exchangeRate, setExchangeRate] = useState<string>();
  const [bankAccount, setBankAccount] = useState<BankAccount[]>();
  const [balance, setBalance] = useState<string>();
  const [thisCoin, setThisCoin] = useState<string>();
  const [thisFiat, setThisFiat] = useState<string>();
  const [batchData, setBatchData] = useState<OneTradingForm[]>(
    stateTradingForm ?? [
      {
        quote: '',
        price: '',
        amount: '',
        minTotal: '',
        maxTotal: '',
        bankAccountId: '',
      },
    ],
  );
  const [hasError, setHasError] = useState<boolean[]>(
    stateTradingForm ? stateTradingForm.map(() => false) : [true],
  );
  const isEdit = useMemo(() => batchData.length > 0 && batchData[0].id !== undefined, [batchData]);

  const available = useMemo(() => {
    if (balance === undefined) return;
    const sum = batchData.reduce((a, b) => a.plus(b.amount.length > 0 ? b.amount : 0), bn(0));

    return bn(balance).minus(sum).toString();
  }, [balance, batchData]);

  const disabled = useMemo(() => {
    if (hasError.find((v) => v === true)) return true;
    for (const v of batchData)
      if (
        v.price === '' ||
        v.amount === '' ||
        v.minTotal === '' ||
        v.maxTotal === '' ||
        v.bankAccountId === ''
      )
        return true;

    return false;
  }, [batchData, hasError]);

  useEffect(() => {
    if (!crypto[0] || !fiat[0]) return;

    setThisCoin(crypto[0].id);
    if (!thisFiat)
      if (batchData[0].quote !== '') setThisFiat(batchData[0].quote);
      else setThisFiat(fiat[0].id);
  }, [crypto, fiat]);

  useEffect(() => {
    if (!thisCoin || !thisFiat) return;

    initTrading(thisCoin, thisFiat)
      .then(([resRate, resBankAccount, resBalance]) => {
        setExchangeRate(resRate.price);
        setBankAccount(resBankAccount);
        setBalance(resBalance.free);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [thisCoin, thisFiat]);

  useEffect(() => {
    if (!thisFiat) return;
    if (batchData[0].quote !== thisFiat) {
      //reset BatchData if quote changed
      setBatchData([
        {
          quote: '',
          price: '',
          amount: '',
          minTotal: '',
          maxTotal: '',
          bankAccountId: '',
        },
      ]);
      //reset hasError
      setHasError([true]);
    }
  }, [thisFiat]);

  const updateBatchData = (i: number) => (key: keyof OneTradingForm, value: string) => {
    const temp = [...batchData];
    temp[i][key] = value;
    setBatchData(temp);
  };

  const onDelete = (i: number) => () => {
    const temp = [...batchData];
    temp.splice(i, 1);
    hasError.splice(i, 1);
    setBatchData(temp);
  };

  const onPreview = () => {
    navigate(Page.TradingBatchConfirm, {
      replace: true,
      state: {
        action: isEdit ? 'edit' : 'trading',
        base: 'usdt',
        quote: thisFiat,
        batchData: batchData.map((v) => ({
          ...v,
          quote: thisFiat,
          bankAccount: bankAccount?.find((b) => b.id === v.bankAccountId),
        })),
      },
    });
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] flex flex-row justify-between text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {isEdit ? t('tradingBatch.heading.edit') : t('tradingBatch.heading.trading')}
        <div className="flex h-fit flex-row gap-[12px]">
          <Button appearance="text" onClick={() => navigate(Page.Trade, { replace: true })}>
            {t('trading.act.trade')}
          </Button>
          <Button appearance="text" onClick={() => navigate(Page.MyTrade, { replace: true })}>
            {t('trading.act.myTrade')}
          </Button>
        </div>
      </div>
      <div className="mt-[30px] flex flex-wrap gap-x-[30px] gap-y-[25px] pb-[25px] md:gap-[60px]">
        <div className="w-[calc(50%-15px)] md:w-[calc(25%-45px)]">
          <div className="text-[14px] text-grey-700 dark:text-grey-300">
            {t('trading.desc.tradeType')}
          </div>
          <div className="mt-2">{t('trading.desc.sell')}</div>
        </div>
        <div className="w-[calc(50%-15px)] md:w-[calc(25%-45px)]">
          <div className="text-[14px] text-grey-700 dark:text-grey-300">
            {t('trading.desc.marketPrice')}
          </div>
          <div className="mt-2 flex items-center justify-between">
            <div>{exchangeRate ? bnFormat(exchangeRate) : '-'}</div>
            <div className="text-grey-700 dark:text-grey-300">
              {thisFiat?.toUpperCase()}/{thisCoin?.toUpperCase()}
            </div>
          </div>
        </div>
        {thisCoin && crypto && (
          <div className="w-[calc(50%-15px)] md:w-[calc(25%-45px)]">
            <Select
              label={t('trading.desc.coinType')}
              defaultValue={thisCoin}
              asterisked
              onChange={(v) => setThisCoin(v)}
            >
              {crypto.map((value) => (
                <SelectOption key={value.id} value={value.id}>
                  {value.id.toUpperCase()}
                </SelectOption>
              ))}
            </Select>
          </div>
        )}
        {thisFiat && fiat && (
          <div className="w-[calc(50%-15px)] md:w-[calc(25%-45px)]">
            <Select
              label={t('trading.desc.currency')}
              value={thisFiat}
              asterisked
              onChange={(v) => setThisFiat(v)}
            >
              {fiat.map((value) => (
                <SelectOption key={value.id} value={value.id}>
                  {value.id.toUpperCase()}
                </SelectOption>
              ))}
            </Select>
          </div>
        )}
      </div>
      <div className="dart:bg-dark-500 mt-[30px] h-[1px] bg-light-400" />
      <div className="sticky top-0 z-10 bg-light-100 pb-[15px] pt-[30px] dark:bg-black-900">
        <div className="text-[14px] text-grey-700 dark:text-grey-300">
          {t('tradingBatch.desc.available')}
        </div>
        <div className="text-[24px] font-bold">
          {available ? bnFormat(available) : '-'} {thisCoin?.toUpperCase()}
        </div>
      </div>
      <div className="flex flex-col gap-[30px]">
        {batchData.map((v, i) => (
          <TradingCard
            key={i}
            idx={i}
            data={v}
            setData={updateBatchData(i)}
            thisFiat={thisFiat}
            fiat={fiat.find((o) => o.id === thisFiat)}
            coin={crypto.find((o) => o.id === thisCoin)}
            exchangeRate={exchangeRate}
            sufficient={bn(available ?? 0).gte(0)}
            onDelete={onDelete(i)}
            bankAccount={bankAccount}
            setHasError={(o) => {
              const temp = [...hasError];
              temp[i] = o;
              setHasError(temp);
            }}
          />
        ))}
      </div>
      {!isEdit && (
        <div
          className="mt-[30px] flex cursor-pointer items-center justify-center gap-[5px] rounded-[4px] border border-solid border-turquoise py-2 dark:border-aqua"
          onClick={() => {
            setBatchData([
              ...batchData,
              {
                quote: '',
                price: '',
                amount: '',
                minTotal: '',
                maxTotal: '',
                bankAccountId: '',
              },
            ]);
            setHasError([...hasError, true]);
          }}
        >
          <img src={IcAdd} />
          <div className="font-bold text-turquoise dark:text-aqua">{t('tradingBatch.act.add')}</div>
        </div>
      )}
      <Alert severity={Severity.Clear} className="mt-[30px]">
        {t('tradingBatch.desc.alert')}
      </Alert>
      <div className="mt-[30px] text-right">
        <Button size="large" type="button" onClick={onPreview} disabled={disabled}>
          {t('trading.act.preview')}
        </Button>
      </div>
    </div>
  );
};

export default TradingBatch;
