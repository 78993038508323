import { format } from 'date-fns';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Image from 'src/component/Image';
import ReceiptConfirmModal from 'src/component/ReceiptConfirmModal';
import ReceiptRejectModal from 'src/component/ReceiptRejectModal';
import { ThemeContext } from 'src/context/ThemeContext';
import { Fill } from 'src/model/Order';
import { openSnackbar } from 'src/redux/uiSlice';
import { confirmFill, rejectFill } from 'src/service/orderService';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  dataArray?: Fill[];
  onRefresh?: () => void;
};

const OrderList = ({ dataArray, onRefresh }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [receiptUrl, setReceiptUrl] = useState<string>('');
  const [fillIdToConfirm, setFillIdToConfirm] = useState<string>('');
  const [fillIdToReject, setFillIdToReject] = useState<string>('');
  const { IcSearch } = useContext(ThemeContext).image;

  const onCheckReceipt = (fill: Fill) => {
    if (fill.receipts.length === 0) return;

    setReceiptUrl(fill.receipts[0].url);
  };

  const onConfirmFill = (tfaCode: string) => {
    confirmFill(fillIdToConfirm, tfaCode)
      .then(() => {
        onRefresh && onRefresh();
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onRejectFill = (tfaCode: string) => {
    rejectFill(fillIdToReject, tfaCode)
      .then(() => {
        onRefresh && onRefresh();
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const elementMapFunction = (fill: Fill) => (
    <div key={fill.id}>
      <div className="mb-[16px] mt-[15px]">
        <div className="mb-[10px] flex flex-row gap-[10px] text-[14px] font-bold">
          <div>
            {`${t('order.desc.amount')} ${bnFormat(fill.amount)} ${fill.base.toUpperCase()}`}
          </div>
          <div>{`${t('order.desc.unitPrice')} ${bnFormat(
            fill.price,
          )} ${fill.quote.toUpperCase()}`}</div>
        </div>
        <div className="flex flex-row text-[12px]">
          <div className="w-1/3 text-grey-500 dark:text-grey-500">{t('order.desc.orderId')}</div>
          <div className="w-2/3">{fill.id}</div>
        </div>
        <div className="mt-[3px] flex flex-row text-[12px]">
          <div className="w-1/3 text-grey-500 dark:text-grey-500">{t('order.desc.date')}</div>
          <div className="w-2/3">{format(Date.parse(fill.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
        <div className="mt-[3px] flex flex-row text-[12px]">
          <div className="w-1/3 text-grey-500 dark:text-grey-500">
            {t('bankAccount.desc.paymentMethodType')}
          </div>
          <div className="w-2/3">
            {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
          </div>
        </div>
        {fill.bankAccount.paymentMethodType === 'bank' && fill.bankAccount.nationality === 'CN' && (
          <>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('order.desc.bankAccountName')}
              </div>
              <div className="w-2/3">{fill.bankAccount.name}</div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('order.desc.bankAccountNumber')}
              </div>
              <div className="w-2/3">{fill.bankAccount.account}</div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">{t('order.desc.total')}</div>
              <div className="w-2/3 font-bold text-turquoise dark:text-aqua">
                {`${bnFormat(fill.total)} ${fill.quote.toUpperCase()}`}
              </div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('order.desc.bankName')}
              </div>
              <div className="w-2/3">{fill.bankAccount.bankName}</div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('order.desc.bankBranchName')}
              </div>
              <div className="w-2/3">{fill.bankAccount.bankBranchName}</div>
            </div>
          </>
        )}
        {fill.bankAccount.paymentMethodType === 'bank' && fill.bankAccount.nationality === 'VN' && (
          <>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('order.desc.bankAccountName')}
              </div>
              <div className="w-2/3">{fill.bankAccount.name}</div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('order.desc.bankAccountNumber')}
              </div>
              <div className="w-2/3">{fill.bankAccount.account}</div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">{t('order.desc.total')}</div>
              <div className="w-2/3 font-bold text-turquoise dark:text-aqua">
                {`${bnFormat(fill.total)} ${fill.quote.toUpperCase()}`}
              </div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('order.desc.bankName')}
              </div>
              <div className="w-2/3">{fill.bankAccount.bankName}</div>
            </div>
          </>
        )}
        {fill.bankAccount.paymentMethodType === 'alipay' && (
          <>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('order.desc.alipayAccountName')}
              </div>
              <div className="w-2/3">{fill.bankAccount.name}</div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('order.desc.alipayAccount')}
              </div>
              <div className="w-2/3">{fill.bankAccount.account}</div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">{t('order.desc.total')}</div>
              <div className="w-2/3 font-bold text-turquoise dark:text-aqua">
                {`${bnFormat(fill.total)} ${fill.quote.toUpperCase()}`}
              </div>
            </div>
          </>
        )}
        {fill.bankAccount.paymentMethodType === 'vietqr' && (
          <>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('order.desc.alipayAccountName')}
              </div>
              <div className="w-2/3">{fill.bankAccount.name}</div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">{t('order.desc.total')}</div>
              <div className="w-2/3 font-bold text-turquoise dark:text-aqua">
                {`${bnFormat(fill.total)} ${fill.quote.toUpperCase()}`}
              </div>
            </div>
          </>
        )}
        {fill.bankAccount.paymentMethodType === 'zalopay' && (
          <>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('bankAccount.desc.account')}
              </div>
              <div className="w-2/3">{fill.bankAccount.account}</div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">{t('order.desc.total')}</div>
              <div className="w-2/3 font-bold text-turquoise dark:text-aqua">
                {`${bnFormat(fill.total)} ${fill.quote.toUpperCase()}`}
              </div>
            </div>
          </>
        )}
        {fill.bankAccount.paymentMethodType === 'momo' && (
          <>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">
                {t('bankAccount.desc.account')}
              </div>
              <div className="w-2/3">{fill.bankAccount.account}</div>
            </div>
            <div className="mt-[3px] flex flex-row text-[12px]">
              <div className="w-1/3 text-grey-500 dark:text-grey-500">{t('order.desc.total')}</div>
              <div className="w-2/3 font-bold text-turquoise dark:text-aqua">
                {`${bnFormat(fill.total)} ${fill.quote.toUpperCase()}`}
              </div>
            </div>
          </>
        )}
        {fill.rejectedAt && (
          <div className="mt-[10px] text-[12px] text-crimson dark:text-red">
            {t('order.desc.rejectHint')}
          </div>
        )}
        {fill.status === 'sent' && (
          <div className="mt-[10px] block text-[12px] lg:flex lg:items-center lg:justify-between lg:gap-[10px]">
            <div>
              {fill.receipts.length > 0 ? (
                <div
                  className="flex cursor-pointer flex-row items-center"
                  onClick={() => onCheckReceipt(fill)}
                >
                  {t('order.act.checkReceipt')}
                  <img src={IcSearch} />
                </div>
              ) : (
                <div>{t('order.act.paymentClaimed')}</div>
              )}
              {fill.sentAt && <div>{format(Date.parse(fill.sentAt), 'yyyy/MM/dd HH:mm:ss')}</div>}
            </div>
            <div className="mt-[10px] flex justify-end gap-[10px]">
              <Button appearance="outlined" size="small" onClick={() => setFillIdToReject(fill.id)}>
                {t('order.act.rejectPayment')}
              </Button>
              <Button
                appearance="secondary"
                size="small"
                onClick={() => setFillIdToConfirm(fill.id)}
              >
                {t('order.act.confirmPayment')}
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <>
      <div className="grow overflow-y-scroll px-[15px] py-[0px]">
        {dataArray?.map(elementMapFunction)}
      </div>
      <Image open={receiptUrl.length > 0} src={receiptUrl} onClose={() => setReceiptUrl('')} />
      <ReceiptRejectModal
        open={fillIdToReject.length > 0}
        onClose={() => setFillIdToReject('')}
        onSubmit={onRejectFill}
      />
      <ReceiptConfirmModal
        open={fillIdToConfirm.length > 0}
        onClose={() => setFillIdToConfirm('')}
        onSubmit={onConfirmFill}
      />
    </>
  );
};

export default OrderList;
