import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import Modal from 'src/component/Modal';
import { Kid } from 'src/model/AccountUser';
import { CommissionForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { editCommission } from 'src/service/agencyService';

type Props = {
  open: boolean;
  handleClose: () => void;
  kid?: Kid;
  doRefresh: () => void;
};

const CommissionModal = ({ open, handleClose, kid, doRefresh }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { fiat } = useSelector((rootState: RootState) => rootState.coin);
  const methods = useForm<CommissionForm>();

  const getCommissionDelta = (data: string) => {
    if (data === 'cny') return kid?.commissionDeltas?.cny;
    else if (data === 'vnd') return kid?.commissionDeltas?.vnd;
  };

  const onSubmit = (data: CommissionForm) => {
    if (!kid) return;
    handleClose();
    editCommission(kid.id, data)
      .then(() => {
        doRefresh();
        methods.reset();
      })
      .catch((e) => dispatch(openSnackbar(e.message)));
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Form methods={methods} onSubmit={onSubmit}>
        <div className="text-[32px]">{t('agency.desc.modalHead')}</div>
        <div className="mt-[10px]">{kid?.email}</div>
        {fiat.map((v) => (
          <>
            <div className="my-[25px] text-[14px] leading-[1.5]">
              {v.id.toUpperCase() + t('agency.desc.currentValue')}
              {getCommissionDelta(v.id)}
            </div>
            <FormNumberInput name={v.id} decimal={v.priceDecimal} />
          </>
        ))}

        <div className="mt-[65px] flex justify-end gap-5">
          <Button appearance="outlined" type="button" onClick={handleClose}>
            {t('act.cancel')}
          </Button>
          <Button type="submit">{t('act.submit')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CommissionModal;
