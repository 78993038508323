import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Image from 'src/component/Image';
import ReceiptConfirmModal from 'src/component/ReceiptConfirmModal';
import ReceiptRejectModal from 'src/component/ReceiptRejectModal';
import { MediaQuery } from 'src/constant/Media';
import { ThemeContext } from 'src/context/ThemeContext';
import { Fill } from 'src/model/Order';
import { openSnackbar } from 'src/redux/uiSlice';
import { confirmFill, getFillById, rejectFill } from 'src/service/orderService';
import { bnFormat } from 'src/util/bigNumber';

const OrderAskDetail = () => {
  const { t } = useTranslation();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const { orderId } = useParams();
  const { IcSearch } = useContext(ThemeContext).image;
  const dispatch = useDispatch();
  const [receiptUrl, setReceiptUrl] = useState<string>();
  const [fill, setFill] = useState<Fill>();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    updateFill();
  }, [orderId, refresh]);

  const updateFill = () => {
    if (orderId === undefined) return;
    getFillById(orderId)
      .then((res) => {
        setFill(res);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onCheckReceipt = (value: Fill) => {
    if (value.receipts.length === 0) return;

    setReceiptUrl(value.receipts[0].url);
  };

  const onConfirmFill = (tfaCode: string) => {
    if (fill === undefined) return;
    setConfirmOpen(false);
    confirmFill(fill.id, tfaCode)
      .then(() => {
        updateFill();
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onRejectFill = (tfaCode: string) => {
    if (fill === undefined) return;
    setRejectOpen(false);
    rejectFill(fill.id, tfaCode)
      .then(() => {
        updateFill();
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  if (fill === undefined) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[28px] font-[600] sm:mt-[20px] sm:text-[32px]">
        {t('orderDetail.heading', { id: orderId })}
      </div>
      <div className="mx-0 my-[15px] flex justify-end">
        <Button appearance="outlined" onClick={() => setRefresh(!refresh)}>
          {t('orderDetail.act.refresh')}
        </Button>
      </div>
      <div className="rounded-[12px] bg-white px-[20px] py-[25px] dark:bg-black-500 xs:px-[45px] xs:py-[40px]">
        <div className="flex flex-col gap-[20px] text-[16px] xs:flex-row xs:flex-wrap xs:gap-x-[10px] xs:gap-y-0 md:gap-y-[30px]">
          <div className="flex w-full items-center justify-between xs:w-[calc((100%-20px)/3)] xs:flex-col xs:items-start xs:justify-start xs:pb-[20px]">
            <div className="text-grey-700 dark:text-grey-300">{t('orderDetail.desc.status')}</div>
            <div className="text-green dark:text-lime">
              <div>{t(`allOrders.desc.statusDisplay.${fill.status}`)}</div>
              {fill.rejectedAt && (
                <div className="text-crimson dark:text-red">{t('orderDetail.desc.rejectHint')}</div>
              )}
            </div>
          </div>
          <div className="flex w-full items-center justify-between xs:w-[calc((100%-20px)/3)] xs:flex-col xs:items-start xs:justify-start xs:pb-[20px]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('orderDetail.desc.createdDate')}
            </div>
            <div>{format(Date.parse(fill.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
          </div>
          <div className="flex w-full items-center justify-between xs:w-[calc((100%-20px)/3)] xs:flex-col xs:items-start xs:justify-start xs:pb-[20px]">
            {fill.canceledAt ? (
              <>
                <div className="text-grey-700 dark:text-grey-300">
                  {t('orderDetail.desc.cancelDate')}
                </div>
                <div>
                  {format(Date.parse(fill.canceledAt || fill.executedAt), 'yyyy/MM/dd HH:mm:ss')}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="flex w-full items-center justify-between xs:w-[calc((100%-20px)/3)] xs:flex-col xs:items-start xs:justify-start xs:pb-[20px]">
            <div className="text-grey-700 dark:text-grey-300">{t('orderDetail.desc.amount')}</div>
            <div>{bnFormat(fill.amount) + ' ' + fill.base.toUpperCase()}</div>
          </div>
          <div className="flex w-full items-center justify-between xs:w-[calc((100%-20px)/3)] xs:flex-col xs:items-start xs:justify-start xs:pb-[20px]">
            <div className="text-grey-700 dark:text-grey-300">
              {t('orderDetail.desc.unitPrice')}
            </div>
            <div>{bnFormat(fill.price) + ' ' + fill.quote.toUpperCase()}</div>
          </div>
          <div className="flex w-full items-center justify-between xs:w-[calc((100%-20px)/3)] xs:flex-col xs:items-start xs:justify-start xs:pb-[20px]">
            <div className="text-grey-700 dark:text-grey-300">{t('orderDetail.desc.total')}</div>
            <div>{bnFormat(fill.total) + ' ' + fill.quote.toUpperCase()}</div>
          </div>
        </div>
        <div className="my-[30px] h-[1px] bg-light-200 dark:bg-dark-700" />
        <div>
          <div className="pb-[15px] text-grey-700 dark:text-grey-300">
            {t('orderDetail.desc.receivePayment')}
          </div>
          {!isBiggerThanMd && (
            <div className="flex flex-col gap-1 text-[14px] leading-[1.5]">
              <div className="flex gap-[15px]">
                <div className="text-grey-700 dark:text-grey-300">
                  {t('bankAccount.desc.paymentMethodType')}
                </div>
                <div>
                  {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
                </div>
              </div>
              {fill?.bankAccount.paymentMethodType === 'bank' &&
                fill?.bankAccount.nationality === 'CN' && (
                  <>
                    <div className="flex gap-[15px]">
                      <div className="text-grey-700 dark:text-grey-300">
                        {t('tradingConfirm.desc.accountName')}
                      </div>
                      <div>{fill?.bankAccount.name}</div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="text-grey-700 dark:text-grey-300">
                        {t('tradingConfirm.desc.bank')}
                      </div>
                      <div>{fill?.bankAccount.bankName}</div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="text-grey-700 dark:text-grey-300">
                        {t('tradingConfirm.desc.bankAccount')}
                      </div>
                      <div>{fill?.bankAccount.account}</div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="text-grey-700 dark:text-grey-300">
                        {t('tradingConfirm.desc.bankProvinceCity')}
                      </div>
                      <div>
                        {fill?.bankAccount.province}/{fill?.bankAccount.city}
                      </div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="text-grey-700 dark:text-grey-300">
                        {t('tradingConfirm.desc.bankBranchName')}
                      </div>
                      <div>{fill?.bankAccount.bankBranchName}</div>
                    </div>
                  </>
                )}
              {fill?.bankAccount.paymentMethodType === 'bank' &&
                fill?.bankAccount.nationality === 'VN' && (
                  <>
                    <div className="flex gap-[15px]">
                      <div className="text-grey-700 dark:text-grey-300">
                        {t('tradingConfirm.desc.accountName')}
                      </div>
                      <div>{fill?.bankAccount.name}</div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="text-grey-700 dark:text-grey-300">
                        {t('tradingConfirm.desc.bank')}
                      </div>
                      <div>{fill?.bankAccount.bankName}</div>
                    </div>
                    <div className="flex gap-[15px]">
                      <div className="text-grey-700 dark:text-grey-300">
                        {t('tradingConfirm.desc.bankAccount')}
                      </div>
                      <div>{fill?.bankAccount.account}</div>
                    </div>
                  </>
                )}
              {fill?.bankAccount.paymentMethodType === 'alipay' && (
                <>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('tradingConfirm.desc.alipayAccountName')}
                    </div>
                    <div>{fill?.bankAccount.name}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('tradingConfirm.desc.alipayAccount')}
                    </div>
                    <div>{fill?.bankAccount.account}</div>
                  </div>
                </>
              )}
              {fill?.bankAccount.paymentMethodType === 'vietqr' && (
                <>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('tradingConfirm.desc.alipayAccountName')}
                    </div>
                    <div>{fill?.bankAccount.name}</div>
                  </div>
                </>
              )}
              {fill?.bankAccount.paymentMethodType === 'zalopay' && (
                <>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('bankAccount.desc.account')}
                    </div>
                    <div>{fill?.bankAccount.account}</div>
                  </div>
                </>
              )}
              {fill?.bankAccount.paymentMethodType === 'momo' && (
                <>
                  <div className="flex gap-[15px]">
                    <div className="text-grey-700 dark:text-grey-300">
                      {t('bankAccount.desc.account')}
                    </div>
                    <div>{fill?.bankAccount.account}</div>
                  </div>
                </>
              )}
            </div>
          )}
          {isBiggerThanMd && (
            <div className="text-[14px] leading-[1.5]">
              {fill?.bankAccount.paymentMethodType === 'bank' &&
                fill?.bankAccount.nationality === 'CN' && (
                  <>
                    <div className="flex flex-1 flex-row text-grey-700 dark:text-grey-300">
                      <span className="box-border w-2/12 px-[12px] py-[16px]">
                        {t('bankAccount.desc.paymentMethodType')}
                      </span>
                      <span className="box-border w-1/12 px-[12px] py-[16px]">
                        {t('tradingConfirm.desc.accountName')}
                      </span>
                      <span className="box-border w-1/12 px-[12px] py-[16px]">
                        {t('tradingConfirm.desc.bank')}
                      </span>
                      <span className="box-border w-2/12 px-[12px] py-[16px]">
                        {t('tradingConfirm.desc.bankAccount')}
                      </span>
                      <span className="box-border w-2/12 px-[12px] py-[16px]">
                        {t('tradingConfirm.desc.bankProvinceName')}
                      </span>
                      <span className="box-border w-2/12 px-[12px] py-[16px]">
                        {t('tradingConfirm.desc.bankCityName')}
                      </span>
                      <span className="box-border w-2/12 px-[12px] py-[16px]">
                        {t('tradingConfirm.desc.bankBranchName')}
                      </span>
                    </div>
                    <div className="flex flex-1 flex-row">
                      <div className="box-border w-2/12 break-all md:px-[12px] md:py-0">
                        {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
                      </div>
                      <div className="box-border w-1/12 break-all md:px-[12px] md:py-0">
                        {fill?.bankAccount.name}
                      </div>
                      <div className="box-border w-1/12 break-all md:px-[12px] md:py-0">
                        {fill?.bankAccount.bankName}
                      </div>
                      <div className="box-border w-2/12 break-all md:px-[12px] md:py-0">
                        {fill?.bankAccount.account}
                      </div>
                      <div className="box-border w-2/12 break-all md:px-[12px] md:py-0">
                        {fill?.bankAccount.province}
                      </div>
                      <div className="box-border w-2/12 break-all md:px-[12px] md:py-0">
                        {fill?.bankAccount.city}
                      </div>
                      <div className="box-border w-2/12 break-all md:px-[12px] md:py-0">
                        {fill?.bankAccount.bankBranchName}
                      </div>
                    </div>
                  </>
                )}
              {fill?.bankAccount.paymentMethodType === 'bank' &&
                fill?.bankAccount.nationality === 'VN' && (
                  <>
                    <div className="flex flex-1 flex-row text-grey-700 dark:text-grey-300">
                      <span className="box-border w-1/4 px-[12px] py-[16px]">
                        {t('bankAccount.desc.paymentMethodType')}
                      </span>
                      <span className="box-border w-1/4 px-[12px] py-[16px]">
                        {t('tradingConfirm.desc.accountName')}
                      </span>
                      <span className="box-border w-1/4 px-[12px] py-[16px]">
                        {t('tradingConfirm.desc.bank')}
                      </span>
                      <span className="box-border w-1/4 px-[12px] py-[16px]">
                        {t('tradingConfirm.desc.bankAccount')}
                      </span>
                    </div>
                    <div className="flex flex-1 flex-row">
                      <div className="box-border w-1/4 break-all md:px-[12px] md:py-0">
                        {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
                      </div>
                      <div className="box-border w-1/4 break-all md:px-[12px] md:py-0">
                        {fill?.bankAccount.name}
                      </div>
                      <div className="box-border w-1/4 break-all md:px-[12px] md:py-0">
                        {fill?.bankAccount.bankName}
                      </div>
                      <div className="box-border w-1/4 break-all md:px-[12px] md:py-0">
                        {fill?.bankAccount.account}
                      </div>
                    </div>
                  </>
                )}
              {fill?.bankAccount.paymentMethodType === 'alipay' && (
                <>
                  <div className="flex flex-1 flex-row text-grey-700 dark:text-grey-300">
                    <span className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('bankAccount.desc.paymentMethodType')}
                    </span>
                    <span className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.alipayAccountName')}
                    </span>
                    <span className="box-border w-4/6 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.alipayAccount')}
                    </span>
                  </div>
                  <div className="flex flex-1 flex-row">
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {fill?.bankAccount.name}
                    </div>
                    <div className="box-border w-4/6 break-all md:px-[12px] md:py-0">
                      {fill?.bankAccount.account}
                    </div>
                  </div>
                </>
              )}
              {fill?.bankAccount.paymentMethodType === 'vietqr' && (
                <>
                  <div className="flex flex-1 flex-row text-grey-700 dark:text-grey-300">
                    <span className="box-border w-1/2 px-[12px] py-[16px]">
                      {t('bankAccount.desc.paymentMethodType')}
                    </span>
                    <span className="box-border w-1/2 px-[12px] py-[16px]">
                      {t('tradingConfirm.desc.alipayAccountName')}
                    </span>
                  </div>
                  <div className="flex flex-1 flex-row">
                    <div className="box-border w-1/2 break-all md:px-[12px] md:py-0">
                      {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
                    </div>
                    <div className="box-border w-1/2 break-all md:px-[12px] md:py-0">
                      {fill?.bankAccount.name}
                    </div>
                  </div>
                </>
              )}
              {fill?.bankAccount.paymentMethodType === 'zalopay' && (
                <>
                  <div className="flex flex-1 flex-row text-grey-700 dark:text-grey-300">
                    <span className="box-border w-1/2 px-[12px] py-[16px]">
                      {t('bankAccount.desc.paymentMethodType')}
                    </span>
                    <span className="box-border w-1/2 px-[12px] py-[16px]">
                      {t('bankAccount.desc.account')}
                    </span>
                  </div>
                  <div className="flex flex-1 flex-row">
                    <div className="box-border w-1/2 break-all md:px-[12px] md:py-0">
                      {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
                    </div>
                    <div className="box-border w-1/2 break-all md:px-[12px] md:py-0">
                      {fill?.bankAccount.account}
                    </div>
                  </div>
                </>
              )}
              {fill?.bankAccount.paymentMethodType === 'momo' && (
                <>
                  <div className="flex flex-1 flex-row text-grey-700 dark:text-grey-300">
                    <span className="box-border w-1/2 px-[12px] py-[16px]">
                      {t('bankAccount.desc.paymentMethodType')}
                    </span>
                    <span className="box-border w-1/2 px-[12px] py-[16px]">
                      {t('bankAccount.desc.account')}
                    </span>
                  </div>
                  <div className="flex flex-1 flex-row">
                    <div className="box-border w-1/2 break-all md:px-[12px] md:py-0">
                      {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
                    </div>
                    <div className="box-border w-1/2 break-all md:px-[12px] md:py-0">
                      {fill?.bankAccount.account}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        {fill.status !== 'canceled' && fill.status !== 'executed' && (
          <div className="flex flex-col gap-[10px] pt-[24px] text-[12px] sm:flex-row sm:justify-between">
            <div>
              {fill.receipts.length > 0 ? (
                <div
                  className="flex cursor-pointer flex-row items-center"
                  onClick={() => onCheckReceipt(fill)}
                >
                  {t('orderDetail.act.checkReceipt')}
                  <img src={IcSearch} />
                </div>
              ) : (
                <div>{t('orderDetail.act.paymentClaimed')}</div>
              )}
              <div>{format(Date.parse(fill.sentAt ?? ''), 'yyyy/MM/dd HH:mm:ss')}</div>
            </div>
            {fill.status !== 'received' && (
              <div className="flex justify-end gap-[10px]">
                <Button appearance="outlined" size="small" onClick={() => setRejectOpen(true)}>
                  {t('orderDetail.act.rejectPayment')}
                </Button>
                <Button appearance="secondary" size="small" onClick={() => setConfirmOpen(true)}>
                  {t('orderDetail.act.confirmPayment')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {receiptUrl && (
        <Image open={true} src={receiptUrl} onClose={() => setReceiptUrl(undefined)} />
      )}
      <ReceiptRejectModal
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onSubmit={onRejectFill}
      />
      <ReceiptConfirmModal
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onSubmit={onConfirmFill}
      />
    </div>
  );
};

export default OrderAskDetail;
