import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Checkbox from 'src/component/Checkbox';
import { Action } from 'src/constant/Order';
import { UserOrder } from 'src/model/Order';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: UserOrder[];
  onAction: (id: string, action: Action) => void;
  isBatchClick: boolean;
  selected: UserOrder[];
  selectAll: boolean;
  onSelectAll: () => void;
  onSelectOne: (id: UserOrder) => void;
};

const Table = ({
  data,
  onAction,
  isBatchClick,
  selected,
  selectAll,
  onSelectAll,
  onSelectOne,
}: Props) => {
  const { t } = useTranslation();

  const elementMapFunction = (order: UserOrder, index: number, array: UserOrder[]) => (
    <div key={order.id}>
      <div className="flex flex-row px-0 py-4">
        <div className="w-1/6 text-[14px]">
          {`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}
        </div>
        <div className="w-2/6 text-[14px]">
          <div className="flex flex-row gap-[10px]">
            <span className="text-[14px] text-grey-700">{t('myTrade.desc.amount')}</span>
            {`${bnFormat(order.openAmount)} ${order.base.toUpperCase()}`}
          </div>
          <div className="flex flex-row gap-[10px]">
            <span className="text-[14px] text-grey-700">{t('myTrade.desc.paymentLimit')}</span>
            {`${bnFormat(order.minTotal)} - ${bnFormat(
              order.maxTotal,
            )} ${order.quote.toUpperCase()}`}
          </div>
        </div>
        <div className="w-1/6 text-[14px]">
          {`${
            order.bankAccount.paymentMethodType === 'bank'
              ? order.bankAccount.bankName
              : t('bankAccount.desc.paymentType.' + order.bankAccount.paymentMethodType)
          }-${order.bankAccount.name ? order.bankAccount.name : order.bankAccount.account}`}
        </div>
        <div className="w-1/6 text-[14px] text-turquoise dark:text-aqua">
          <div>
            {order.status === 'open'
              ? t('myTrade.desc.statusOpen')
              : t('myTrade.desc.statusSuspend')}
          </div>
          {order.status === 'open' && order.suspendedAt && (
            <div>
              {t('myTrade.desc.statusSuspendDate', {
                date: format(Date.parse(order.suspendedAt), 'yyyy/MM/dd HH:mm'),
              })}
            </div>
          )}
        </div>
        <div className="flex w-1/6 items-center justify-end">
          {isBatchClick && (
            <div className="pr-3">
              <Checkbox
                checked={selected.map((v) => v.id).includes(order.id)}
                onChange={() => onSelectOne(order)}
              />
            </div>
          )}
          {!isBatchClick && order.status === 'open' && (
            <div className="flex flex-col items-center gap-[10px] lg:flex-row">
              <Button
                appearance="secondary"
                size="small"
                onClick={() => onAction(order.id, Action.Edit)}
              >
                {t('myTrade.act.edit')}
              </Button>
              <Button
                appearance="secondary"
                size="small"
                onClick={() => onAction(order.id, Action.Suspend)}
              >
                {t('myTrade.act.close')}
              </Button>
            </div>
          )}
          {!isBatchClick && order.status === 'suspended' && (
            <div className="flex flex-col flex-wrap items-center justify-end gap-[10px] lg:flex-row">
              <Button
                appearance="secondary"
                size="small"
                onClick={() => onAction(order.id, Action.Edit)}
              >
                {t('myTrade.act.edit')}
              </Button>
              <Button
                appearance="secondary"
                size="small"
                onClick={() => onAction(order.id, Action.Unsuspend)}
              >
                {t('myTrade.act.republish')}
              </Button>
              <Button
                appearance="secondary"
                size="small"
                onClick={() => onAction(order.id, Action.Delete)}
              >
                {t('myTrade.act.delete')}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames('h-[1px] bg-light-200 dark:bg-dark-700', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row pb-4 text-[14px] font-bold leading-[1.5] text-grey-700">
        <div className="w-1/6">{t('myTrade.desc.unitPrice')}</div>
        <div className="w-2/6">{`${t('myTrade.desc.amount')}/${t(
          'myTrade.desc.paymentLimit',
        )}`}</div>
        <div className="w-1/6">{t('myTrade.desc.payables')}</div>
        <div className="w-1/6">{t('myTrade.desc.status')}</div>
        <div className="w-1/6">
          {isBatchClick && (
            <div className="flex items-center justify-end pr-3">
              <Checkbox checked={selectAll} onChange={onSelectAll} />
            </div>
          )}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default Table;
