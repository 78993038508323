import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import { Page } from 'src/constant/Page';
import { Bank } from 'src/model/Bank';
import { Fiat } from 'src/model/Coin';
import {
  AlipayAccountForm,
  BankAccountForm,
  MomoAccountForm,
  VietqrAccountForm,
  ZalopayAccountForm,
} from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { createBankAccount, getBank, getPaymentMethodTypes } from 'src/service/accountService';
import AlipayForm from './component/AlipayForm';
import CNYBankForm from './component/CNYBankForm';
import MomoForm from './component/MomoForm';
import VietqrForm from './component/VietqrForm';
import VNDBankForm from './component/VNDBankForm';
import ZalopayForm from './component/ZalopayForm';

const BankAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState<string>('bank');
  const [bank, setBank] = useState<Bank[]>();
  const { fiat } = useSelector((rootState: RootState) => rootState.coin);

  const [isTradablefiats, setIsTradablefiats] = useState<Fiat[]>();

  const [fiats, setFiats] = useState<string>('cny');
  const [paymentMethodTypes, setPaymentMethodTypes] = useState<string[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (fiat.length < 0) return;
    setIsTradablefiats(fiat.filter((v) => v.isTradable));
  }, [fiat]);

  useEffect(() => {
    if (isTradablefiats !== undefined) return;
    setFiats('cny');
  }, [isTradablefiats]);

  useEffect(() => {
    getBank()
      .then((res) => setBank(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getPaymentMethodTypes(fiats)
      .then((res) => {
        setPaymentMethodTypes(res);
        setType('bank');
        setIsLoading(false);
      })
      .catch((e) => {
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
        setIsLoading(false);
      });
  }, [fiats]);

  const getFiatBankList = (data: Bank[]) => {
    let nationality = 'CN';
    switch (fiats) {
      case 'cny': {
        nationality = 'CN';
        break;
      }
      case 'vnd': {
        nationality = 'VN';
        break;
      }
    }
    const filteredItems = data.filter((item) => item.nationality === nationality);

    return filteredItems;
  };

  const onSubmit = (
    data:
      | BankAccountForm
      | AlipayAccountForm
      | MomoAccountForm
      | ZalopayAccountForm
      | VietqrAccountForm,
  ) => {
    let nationality = 'CN';
    switch (fiats) {
      case 'cny': {
        nationality = 'CN';
        break;
      }
      case 'vnd': {
        nationality = 'VN';
        break;
      }
    }
    createBankAccount({ ...data, nationality, fiat: fiats, paymentMethodType: type })
      .then(() => navigate(Page.Account))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  if (!bank || isLoading || isTradablefiats?.length === 0) return <></>;

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('bankAccount.heading')}
      </div>
      <div className="mt-[10px] text-[14px]">{t('bankAccount.headDetail')}</div>
      <div className="mx-auto mt-[30px]">
        <div className="md:flex md:flex-row">
          <div className="mt-[20px] box-border xs:px-[30px] md:w-1/3">
            <Select
              label={t('bankAccount.desc.currency')}
              defaultValue={fiats}
              onChange={(v) => setFiats(v)}
            >
              {isTradablefiats?.map((v) => (
                <SelectOption key={v.id} value={v.id}>
                  {v.id.toUpperCase()}
                </SelectOption>
              ))}
            </Select>
          </div>

          <div className="mt-[20px] box-border xs:px-[30px] md:w-1/3">
            <Select
              label={t('bankAccount.desc.paymentMethodType')}
              defaultValue={type}
              onChange={(v) => setType(v)}
            >
              {paymentMethodTypes?.map((v) => (
                <SelectOption key={v} value={v}>
                  {t(`bankAccount.desc.paymentType.${v}`)}
                </SelectOption>
              ))}
            </Select>
          </div>
        </div>
      </div>
      {type === 'bank' && fiats === 'vnd' && (
        <VNDBankForm bank={getFiatBankList(bank)} onSubmit={onSubmit} />
      )}
      {type === 'bank' && fiats === 'cny' && (
        <CNYBankForm bank={getFiatBankList(bank)} onSubmit={onSubmit} />
      )}
      {type === 'alipay' && <AlipayForm onSubmit={onSubmit} />}
      {type === 'vietqr' && <VietqrForm onSubmit={onSubmit} />}
      {type === 'zalopay' && <ZalopayForm onSubmit={onSubmit} />}
      {type === 'momo' && <MomoForm onSubmit={onSubmit} />}
    </div>
  );
};

export default BankAccount;
