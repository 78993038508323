import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Checkbox from 'src/component/Checkbox';
import { Action } from 'src/constant/Order';
import { UserOrder } from 'src/model/Order';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: UserOrder[];
  onAction: (id: string, action: Action) => void;
  isBatchClick: boolean;
  selected: UserOrder[];
  onSelectOne: (v: UserOrder) => void;
};

const Card = ({ data, onAction, isBatchClick, selected, onSelectOne }: Props) => {
  const { t } = useTranslation();

  const elementMapFunction = (order: UserOrder, index: number, array: UserOrder[]) => (
    <div key={order.id}>
      <div className="relative">
        <div className="flex gap-[15px] text-[14px] leading-[1.5]">
          <span className="font-bold text-grey-700">{t('myTrade.desc.unitPrice')}</span>
          {`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}
        </div>
        <div className="mt-1 flex gap-[15px] text-[14px] leading-[1.5]">
          <span className="font-bold text-grey-700">{t('myTrade.desc.amount')}</span>
          {`${bnFormat(order.openAmount)} ${order.base.toUpperCase()}`}
        </div>
        <div className="mt-1 flex gap-[15px] text-[14px] leading-[1.5]">
          <span className="font-bold text-grey-700">{t('myTrade.desc.paymentLimit')}</span>
          {`${bnFormat(order.minTotal)} - ${bnFormat(order.maxTotal)} ${order.quote.toUpperCase()}`}
        </div>
        <div className="mt-1 flex gap-[15px] text-[14px] leading-[1.5]">
          <span className="font-bold text-grey-700">{t('myTrade.desc.payables')}</span>
          {`${
            order.bankAccount.paymentMethodType === 'bank'
              ? order.bankAccount.bankName
              : t('bankAccount.desc.paymentType.' + order.bankAccount.paymentMethodType)
          }-${order.bankAccount.name ? order.bankAccount.name : order.bankAccount.account}`}
        </div>
        <div className="mt-1 flex gap-[15px] text-[14px] leading-[1.5]">
          <div className="font-bold text-grey-700">{t('myTrade.desc.status')}</div>
          <div className="!text-turquoise dark:!text-aqua">
            {order.status === 'open'
              ? t('myTrade.desc.statusOpen')
              : t('myTrade.desc.statusSuspend')}
            {order.status === 'open' &&
              order.suspendedAt &&
              t('myTrade.desc.statusSuspendDate', {
                date: format(Date.parse(order.suspendedAt), 'yyyy/MM/dd HH:mm'),
              })}
          </div>
        </div>
        {isBatchClick && (
          <div className="absolute right-0 top-1/2 -translate-y-1/2">
            <Checkbox
              checked={selected.map((v) => v.id).includes(order.id)}
              onChange={() => onSelectOne(order)}
            />
          </div>
        )}
        {!isBatchClick && (
          <div className="mt-[10px]">
            {order.status === 'open' && (
              <div className="flex justify-end gap-3">
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => onAction(order.id, Action.Suspend)}
                >
                  {t('myTrade.act.close')}
                </Button>
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => onAction(order.id, Action.Edit)}
                >
                  {t('myTrade.act.edit')}
                </Button>
              </div>
            )}
            {order.status === 'suspended' && (
              <div className="flex justify-end gap-3">
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => onAction(order.id, Action.Delete)}
                >
                  {t('myTrade.act.delete')}
                </Button>
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => onAction(order.id, Action.Unsuspend)}
                >
                  {t('myTrade.act.republish')}
                </Button>
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => onAction(order.id, Action.Edit)}
                >
                  {t('myTrade.act.edit')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={classNames('mx-0 my-4 h-[1px] bg-light-200 dark:bg-dark-700', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
