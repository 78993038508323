import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'src/context/ThemeContext';
import { Kid } from 'src/model/AccountUser';

type Props = {
  kids: Kid[];
  onEdit: (id: string) => () => void;
};

const Card = ({ kids, onEdit }: Props) => {
  const { t } = useTranslation();
  const { IcEdit } = useContext(ThemeContext).image;

  return (
    <div className="flex flex-col gap-[15px]">
      {kids.map((v) => (
        <div
          key={v.id}
          className="relative border-0 !border-b border-solid border-light-200 pb-[15px] dark:border-dark-700"
        >
          <div className="flex gap-[15px] text-sm leading-[1.5]">
            <div className="font-bold text-grey-700">{t('agency.desc.kid')}</div>
            <div>{v.email}</div>
          </div>
          <div className="mt-1 flex gap-[15px] text-sm leading-[1.5]">
            <div className="font-bold text-grey-700">{t('agency.desc.commission')}</div>
            <div className="flex flex-wrap gap-[10px]">
              {v.commissionDeltas?.cny !== undefined && (
                <div>{`CNY: ${v.commissionDeltas.cny}`}</div>
              )}
              {v.commissionDeltas?.vnd !== undefined && (
                <div>{`VND: ${v.commissionDeltas.vnd}`}</div>
              )}
            </div>
          </div>
          <img
            src={IcEdit}
            className="absolute bottom-[15px] right-0 h-[21px] w-[21px] cursor-pointer"
            onClick={onEdit(v.id)}
          />
        </div>
      ))}
    </div>
  );
};

export default Card;
