import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Tabs from 'src/component/Tabs';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { Fill } from 'src/model/Order';
import { openSnackbar } from 'src/redux/uiSlice';
import { getFills } from 'src/service/orderService';
import { bn } from 'src/util/bigNumber';
import OrderList from './component/OrderList';

const OrderAskPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sentFills, setSentFills] = useState<Fill[]>();
  const [executedFills, setExecutedFills] = useState<Fill[]>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(false);
  const isBiggerThanXsm = useMediaPredicate(MediaQuery.Xsm);

  useEffect(() => {
    getFills('sent,executed')
      .then((res) => {
        const sent: Fill[] = [];
        const executed: Fill[] = [];

        res.data.map((fill) => {
          if (fill.status === 'sent') sent.push(fill);
          if (fill.status === 'executed') executed.push(fill);
        });
        setSentFills(sent);
        setExecutedFills(executed);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [refresh]);

  const onClickAllOrders = () => {
    navigate(Page.OrderAll);
  };

  const getAmount = (fills: Fill[]) => {
    const cnyFills = fills.filter((v) => v.quote === 'cny');
    const vndFills = fills.filter((v) => v.quote === 'vnd');

    const cnyAmount = cnyFills.reduce((sum, current) => sum.plus(bn(current.total)), bn(0));
    const vndAmount = vndFills.reduce((sum, current) => sum.plus(bn(current.total)), bn(0));

    let dotString = ', ';
    let cnyString = '';
    let vndString = '';
    if (!cnyAmount.isEqualTo(0)) cnyString = `${cnyAmount.toFormat()} ${'CNY'}`;

    if (!vndAmount.isEqualTo(0)) vndString = `${vndAmount.toFormat()} ${'VND'}`;

    if (cnyString === '' || vndString === '') dotString = '';

    return `${cnyString}${dotString}${vndString}`;
  };

  return (
    <div className="box-border flex h-full flex-col !pb-[60px] sm:!pb-[90px]">
      <BackButton />
      <div className="mt-[10px] flex flex-row items-start justify-between text-[28px] font-bold sm:mt-[20px] sm:text-[32px]">
        {t('order.heading')}
        <Button appearance="text" onClick={onClickAllOrders}>
          {t('order.act.allOrders')}
        </Button>
      </div>
      {isBiggerThanXsm && (
        <div className="mx-[0px] my-[15px] flex justify-end">
          <Button appearance="outlined" onClick={() => setRefresh(!refresh)}>
            {t('order.act.refresh')}
          </Button>
        </div>
      )}
      {!isBiggerThanXsm && (
        <div className="relative mx-[0px] my-[15px]">
          <Tabs
            labels={[t('order.act.inPaymentWaiting'), t('order.act.inPaymentClaimed')]}
            onChange={(index) => setTabIndex(index)}
            defaultIndex={tabIndex}
          />
          <div className="absolute bottom-0 right-0">
            <Button appearance="outlined" onClick={() => setRefresh(!refresh)}>
              {t('order.act.refresh')}
            </Button>
          </div>
        </div>
      )}
      <div className="flex grow flex-row gap-[20px] overflow-y-hidden">
        {(isBiggerThanXsm || tabIndex === 0) && (
          <div className="flex flex-1 flex-col overflow-y-hidden rounded-[4px] bg-white dark:bg-black-800">
            <div className="p-[15px] text-[16px] font-bold">
              {t('order.desc.amountInWaiting')}{' '}
              <span className="text-[12px] text-turquoise dark:text-aqua">
                {executedFills && executedFills.length > 0 ? getAmount(executedFills) : '-'}
              </span>
            </div>
            <div className="h-[1px] bg-light-400 dark:bg-dark-500" />
            <OrderList dataArray={executedFills} />
          </div>
        )}
        {(isBiggerThanXsm || tabIndex === 1) && (
          <div className="flex flex-1 flex-col overflow-y-hidden rounded-[4px] bg-white dark:bg-black-800">
            <div className="p-[15px] text-[16px] font-bold">
              {t('order.desc.amountInClaimed')}{' '}
              <span className="text-[12px] text-turquoise dark:text-aqua">
                {sentFills && sentFills.length > 0 ? getAmount(sentFills) : '-'}
              </span>
            </div>
            <div className="h-[1px] bg-light-400 dark:bg-dark-500" />
            <OrderList dataArray={sentFills} onRefresh={() => setRefresh(!refresh)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderAskPage;
