import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import { VietqrAccountForm } from 'src/model/Form';
import { file2Base64 } from 'src/util/fileConverter';

type Props = {
  onSubmit: (data: VietqrAccountForm) => void;
};

const VietqrForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation();

  const [stateOpenUpload, setOpenUpload] = useState(false);
  const onClose = () => {
    setOpenUpload(false);
  };

  const methods = useForm<VietqrAccountForm>();
  const { setValue } = methods;

  const [stateQRCode, setQRCode] = useState('');
  const onUploadQRCode = useCallback(async (file: File) => {
    const bs64 = await file2Base64(file);
    setQRCode(bs64);
    setValue('image', '');
  }, []);

  const handleSubmit = (data: VietqrAccountForm) => {
    if (stateQRCode === '') return;

    const payload: VietqrAccountForm = { ...data, image: stateQRCode };
    onSubmit(payload);
  };

  return (
    <Form className="mx-auto mt-[55px]" methods={methods} onSubmit={handleSubmit}>
      <div className="mb-[15px] mt-[40px] text-[20px] font-bold">
        {t('bankAccount.desc.accountInformation')}
      </div>
      <div className="mt-[20px] xs:px-[30px]">
        <FormInput
          name="name"
          label={t('bankAccount.desc.alipayAccountName')}
          type="text"
          asterisked
          required
        />
      </div>
      <div className="mx-[0px] mt-[48px] flex items-center">
        <div className="font-bold">{t('bankAccount.desc.alipayQRCode')}</div>
        <div className="text-red">*</div>
        <div className="mx-[16px]">
          <Button
            type={'button'}
            appearance="outlined"
            onClick={() => {
              setOpenUpload(true);
            }}
          >
            {t('bankAccount.desc.uploadQRCode')}
          </Button>
          <UploadModal open={stateOpenUpload} onClose={onClose} onSubmit={onUploadQRCode} />
        </div>
      </div>
      <div className={'mt-[16px] cursor-pointer'}>
        {stateQRCode ? (
          <img src={`data:image/jpeg;base64,${stateQRCode}`} alt="Base64 Encoded" />
        ) : null}
      </div>
      <div className="mt-[24px] text-end xs:pr-[30px]">
        <Button size="large" type="submit">
          {t('bankAccount.act.submit')}
        </Button>
      </div>
    </Form>
  );
};

export type UploadModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (file: File) => void;
};

const UploadModal = ({ open, onClose, onSubmit }: UploadModalProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) onSubmit(e.target.files[0]);
    if (onClose) onClose();
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <div>
        <div className="mb-[20px] text-[32px] font-bold">{t('bankAccount.desc.uploadQRCode')}</div>
        <div>{t('bankAccount.desc.uploadContent')}</div>
        <br />
        <div>{t('bankAccount.desc.uploadAccept')}</div>
        <div>{t('bankAccount.desc.uploadLimit')}</div>
        <div className="mt-[40px] flex flex-row justify-end gap-[20px]">
          <Button appearance="outlined" onClick={onClose} type="button">
            {t('act.cancel')}
          </Button>
          <Button type="button" onClick={() => fileInputRef.current?.click()}>
            {t('bankAccount.desc.selectFile')}
          </Button>
          <input
            type="file"
            onChange={handleChange}
            ref={fileInputRef}
            className="hidden"
            accept="image/png,image/jpeg"
          />
        </div>
      </div>
    </Modal>
  );
};

export default VietqrForm;
