import { useTranslation } from 'react-i18next';
import Radio from 'src/component/Radio';
import { BankAccount } from 'src/model/Bank';

type Props = {
  accountArray: BankAccount[];
  defaultIndex?: number;
  fiat: string;
};

const PaymentTable = ({ accountArray, defaultIndex, fiat }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      {fiat === 'cny' && (
        <>
          <div className="mb-[15px] mt-[30px] font-bold text-black-900 dark:text-white">
            {t('bankAccount.desc.paymentType.bankCN')}
            <div className="border-0 md:flex md:flex-row md:border-b md:border-solid md:border-b-light-200 dark:md:border-b-dark-700">
              <div className="pt-[3px] md:w-[40px] md:pt-0" />
              <div className="flex flex-1 flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
                <span className="box-border w-1/6 px-[12px] py-[16px]">
                  {t('trading.desc.accountName')}
                </span>
                <span className="box-border w-1/6 px-[12px] py-[16px]">
                  {t('trading.desc.bank')}
                </span>
                <span className="box-border w-1/6 px-[12px] py-[16px]">
                  {t('trading.desc.bankAccount')}
                </span>
                <span className="box-border w-1/6 px-[12px] py-[16px]">
                  {t('trading.desc.bankProvinceName')}
                </span>
                <span className="box-border w-1/6 px-[12px] py-[16px]">
                  {t('trading.desc.bankCityName')}
                </span>
                <span className="box-border w-1/6 px-[12px] py-[16px]">
                  {t('trading.desc.bankBranchName')}
                </span>
              </div>
            </div>
          </div>
          {accountArray
            .filter((v) => v.paymentMethodType === 'bank' && v.nationality === 'CN')
            .map((v, index) => (
              <div key={v.id}>
                <div className="flex px-0 py-[16px]">
                  <div className="mr-[8px] mt-[1px] flex flex-row items-start justify-center pt-[3px] md:m-0 md:w-[40px] md:items-center md:pt-0">
                    <Radio value={v.id} defaultChecked={index === defaultIndex} />
                  </div>
                  <label htmlFor={v.id} className="flex flex-1 flex-row text-[14px]">
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">{v.name}</div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {v.bankName}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {v.account}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {v.province}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">{v.city}</div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {v.bankBranchName}
                    </div>
                  </label>
                </div>
                <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
              </div>
            ))}

          <div className="mb-[15px] mt-[30px] font-bold text-black-900 dark:text-white">
            {t('trading.desc.alipay')}
          </div>
          <div className="border-0 md:flex md:flex-row md:border-b md:border-solid md:border-b-light-200 dark:md:border-b-dark-700">
            <div className="pt-[3px] md:w-[40px] md:pt-0" />
            <div className="flex flex-1 flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
              <span className="box-border w-1/6 px-[12px] py-[16px]">
                {t('trading.desc.alipayAccountName')}
              </span>
              <span className="box-border w-1/6 px-[12px] py-[16px]">
                {t('trading.desc.alipayAccount')}
              </span>
              <span className="box-border w-4/6 px-[12px] py-[16px]">
                {t('trading.desc.alipayQRCode')}
              </span>
            </div>
          </div>
          {accountArray
            .filter((v) => v.paymentMethodType === 'alipay')
            .map((v, index) => (
              <div key={v.id}>
                <div className="flex px-0 py-[16px] ">
                  <div className="mr-[8px] mt-[1px] flex flex-row items-start justify-center pt-[3px] md:m-0 md:w-[40px] md:items-center md:pt-0">
                    <Radio value={v.id} defaultChecked={index === defaultIndex} />
                  </div>
                  <label htmlFor={v.id} className="flex flex-1 flex-row items-center text-[14px]">
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">{v.name}</div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {v.account}
                    </div>
                    <div className="box-border w-4/6 break-all md:px-[12px] md:py-0">
                      {v.imageUrl
                        ? t('trading.desc.alipayQRCodeUploaded')
                        : t('trading.desc.alipayQRCodeUnuploaded')}
                    </div>
                  </label>
                </div>
                <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
              </div>
            ))}
        </>
      )}

      {fiat === 'vnd' && (
        <>
          <div className="mb-[15px] mt-[30px] font-bold text-black-900 dark:text-white">
            {t('bankAccount.desc.paymentType.bankVN')}
            <div className="border-0 md:flex md:flex-row md:border-b md:border-solid md:border-b-light-200 dark:md:border-b-dark-700">
              <div className="pt-[3px] md:w-[40px] md:pt-0" />
              <div className="flex flex-1 flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
                <span className="box-border w-3/6 px-[12px] py-[16px]">
                  {t('trading.desc.accountName')}
                </span>
                <span className="box-border w-3/6 px-[12px] py-[16px]">
                  {t('trading.desc.bank')}
                </span>
                <span className="box-border w-3/6 px-[12px] py-[16px]">
                  {t('trading.desc.bankAccount')}
                </span>
              </div>
            </div>
          </div>
          {accountArray
            .filter((v) => v.paymentMethodType === 'bank' && v.nationality === 'VN')
            .map((v, index) => (
              <div key={v.id}>
                <div className="flex px-0 py-[16px]">
                  <div className="mr-[8px] mt-[1px] flex flex-row items-start justify-center pt-[3px] md:m-0 md:w-[40px] md:items-center md:pt-0">
                    <Radio value={v.id} defaultChecked={index === defaultIndex} />
                  </div>
                  <label htmlFor={v.id} className="flex flex-1 flex-row text-[14px]">
                    <div className="box-border w-3/6 break-all md:px-[12px] md:py-0">{v.name}</div>
                    <div className="box-border w-3/6 break-all md:px-[12px] md:py-0">
                      {v.bankName}
                    </div>
                    <div className="box-border w-3/6 break-all md:px-[12px] md:py-0">
                      {v.account}
                    </div>
                  </label>
                </div>
                <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
              </div>
            ))}
          <div className="mb-[15px] mt-[30px] font-bold text-black-900 dark:text-white">
            {t('bankAccount.desc.paymentType.vietqr')}
          </div>
          <div className="border-0 md:flex md:flex-row md:border-b md:border-solid md:border-b-light-200 dark:md:border-b-dark-700">
            <div className="pt-[3px] md:w-[40px] md:pt-0" />
            <div className="flex flex-1 flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
              <span className="box-border w-2/6 px-[12px] py-[16px]">
                {t('trading.desc.alipayAccountName')}
              </span>
              <span className="box-border w-4/6 px-[12px] py-[16px]">
                {t('trading.desc.alipayQRCode')}
              </span>
            </div>
          </div>
          {accountArray
            .filter((v) => v.paymentMethodType === 'vietqr')
            .map((v, index) => (
              <div key={v.id}>
                <div className="flex px-0 py-[16px] ">
                  <div className="mr-[8px] mt-[1px] flex flex-row items-start justify-center pt-[3px] md:m-0 md:w-[40px] md:items-center md:pt-0">
                    <Radio value={v.id} defaultChecked={index === defaultIndex} />
                  </div>
                  <label htmlFor={v.id} className="flex flex-1 flex-row items-center text-[14px]">
                    <div className="box-border w-2/6 break-all md:px-[12px] md:py-0">{v.name}</div>
                    <div className="box-border w-4/6 break-all md:px-[12px] md:py-0">
                      {v.imageUrl
                        ? t('trading.desc.alipayQRCodeUploaded')
                        : t('trading.desc.alipayQRCodeUnuploaded')}
                    </div>
                  </label>
                </div>
                <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
              </div>
            ))}
          <div className="mb-[15px] mt-[30px] font-bold text-black-900 dark:text-white">
            {t('bankAccount.desc.paymentType.zalopay')}
          </div>
          <div className="border-0 md:flex md:flex-row md:border-b md:border-solid md:border-b-light-200 dark:md:border-b-dark-700">
            <div className="pt-[3px] md:w-[40px] md:pt-0" />
            <div className="flex flex-1 flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
              <span className="box-border w-2/6 px-[12px] py-[16px]">
                {t('bankAccount.desc.account')}
              </span>
              <span className="box-border w-4/6 px-[12px] py-[16px]">
                {t('trading.desc.alipayQRCode')}
              </span>
            </div>
          </div>
          {accountArray
            .filter((v) => v.paymentMethodType === 'zalopay')
            .map((v, index) => (
              <div key={v.id}>
                <div className="flex px-0 py-[16px] ">
                  <div className="mr-[8px] mt-[1px] flex flex-row items-start justify-center pt-[3px] md:m-0 md:w-[40px] md:items-center md:pt-0">
                    <Radio value={v.id} defaultChecked={index === defaultIndex} />
                  </div>
                  <label htmlFor={v.id} className="flex flex-1 flex-row items-center text-[14px]">
                    <div className="box-border w-2/6 break-all md:px-[12px] md:py-0">
                      {v.account}
                    </div>
                    <div className="box-border w-4/6 break-all md:px-[12px] md:py-0">
                      {v.imageUrl
                        ? t('trading.desc.alipayQRCodeUploaded')
                        : t('trading.desc.alipayQRCodeUnuploaded')}
                    </div>
                  </label>
                </div>
                <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
              </div>
            ))}
          <div className="mb-[15px] mt-[30px] font-bold text-black-900 dark:text-white">
            {t('bankAccount.desc.paymentType.momo')}
          </div>
          <div className="border-0 md:flex md:flex-row md:border-b md:border-solid md:border-b-light-200 dark:md:border-b-dark-700">
            <div className="pt-[3px] md:w-[40px] md:pt-0" />
            <div className="flex flex-1 flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
              <span className="box-border w-2/6 px-[12px] py-[16px]">
                {t('bankAccount.desc.account')}
              </span>

              <span className="box-border w-4/6 px-[12px] py-[16px]">
                {t('trading.desc.alipayQRCode')}
              </span>
            </div>
          </div>
          {accountArray
            .filter((v) => v.paymentMethodType === 'momo')
            .map((v, index) => (
              <div key={v.id}>
                <div className="flex px-0 py-[16px] ">
                  <div className="mr-[8px] mt-[1px] flex flex-row items-start justify-center pt-[3px] md:m-0 md:w-[40px] md:items-center md:pt-0">
                    <Radio value={v.id} defaultChecked={index === defaultIndex} />
                  </div>
                  <label htmlFor={v.id} className="flex flex-1 flex-row items-center text-[14px]">
                    <div className="box-border w-2/6 break-all md:px-[12px] md:py-0">
                      {v.account}
                    </div>
                    <div className="box-border w-4/6 break-all md:px-[12px] md:py-0">
                      {v.imageUrl
                        ? t('trading.desc.alipayQRCodeUploaded')
                        : t('trading.desc.alipayQRCodeUnuploaded')}
                    </div>
                  </label>
                </div>
                <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default PaymentTable;
