import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import RadioGroup from 'src/component/RadioGroup';
import SearchInput from 'src/component/SearchInput';
import Tabs from 'src/component/Tabs';
import { MediaQuery } from 'src/constant/Media';
import IcSortDefault from 'src/image/ic-sort-default.svg';
import IcSortDown from 'src/image/ic-sort-down.svg';
import IcSortUp from 'src/image/ic-sort-up.svg';
import { BankAccount } from 'src/model/Bank';
import { compare } from 'src/util/compare';
import PaymentCard from './PaymentCard';
import PaymentTable from './PaymentTable';

type Props = {
  name: string;
  bankArray: BankAccount[];
  defaultSelected?: string;
  fiat?: string;
  setBankAccountId: (id: string) => void;
};

const PaymentMethodsList = ({
  name,
  bankArray,
  defaultSelected,
  setBankAccountId,
  fiat,
}: Props) => {
  const { t } = useTranslation();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sorting, setSorting] = useState<'default' | 'asc' | 'desc'>('default');
  const [sortedKey, setSortedKey] = useState<keyof BankAccount>();
  const [tab, setTab] = useState<number>(0);

  useEffect(() => {
    const bank = bankArray.find((value) => value.id === defaultSelected);
    setTab(0);
    switch (bank?.paymentMethodType) {
      case 'bank': {
        setTab(0);
        break;
      }
      case 'alipay': {
        setTab(1);
        break;
      }
      case 'vietqr': {
        setTab(1);
        break;
      }
      case 'zalopay': {
        setTab(2);
        break;
      }
      case 'momo': {
        setTab(3);
        break;
      }
    }
  }, [bankArray, defaultSelected]);

  const sortedBankArray = useMemo(() => {
    if (sorting === 'default')
      return bankArray.filter(
        (v) => v.name?.includes(searchValue) || v.account?.includes(searchValue),
      );
    if (sortedKey === 'name')
      return bankArray
        .filter((v) => v.name?.includes(searchValue) || v.account?.includes(searchValue))
        .sort(compare('name', sorting));
    if (sortedKey === 'bankName')
      return bankArray
        .filter((v) => v.name?.includes(searchValue) || v.account?.includes(searchValue))
        .sort(compare('bankName', sorting));

    return bankArray.filter(
      (v) => v.name?.includes(searchValue) || v.account?.includes(searchValue),
    );
  }, [bankArray, sorting, sortedKey, searchValue]);

  const onClickSorting = (key: keyof BankAccount) => () => {
    if (key === 'name' && sortedKey === 'bankName') {
      setSortedKey('name');
      setSorting('asc');
    } else if (key === 'bankName' && sortedKey === 'name') {
      setSortedKey('bankName');
      setSorting('asc');
    } else if (sorting === 'asc') setSorting('desc');
    else if (sorting === 'desc') setSorting('default');
    else {
      setSortedKey(key);
      setSorting('asc');
    }
  };

  const getSortingImage = (key: keyof BankAccount) => {
    if (key === 'name' && sortedKey === 'name') {
      if (sorting === 'asc') return IcSortUp;
      if (sorting === 'desc') return IcSortDown;
    } else if (key === 'bankName' && sortedKey === 'bankName') {
      if (sorting === 'asc') return IcSortUp;
      if (sorting === 'desc') return IcSortDown;
    }

    return IcSortDefault;
  };

  const getTabArray = () => {
    if (fiat === 'cny')
      return [t('bankAccount.desc.paymentType.bankCN'), t('bankAccount.desc.paymentType.alipay')];
    if (fiat === 'vnd')
      return [
        t('bankAccount.desc.paymentType.bankVN'),
        t('bankAccount.desc.paymentType.vietqr'),
        t('bankAccount.desc.paymentType.zalopay'),
        t('bankAccount.desc.paymentType.momo'),
      ];

    return [];
  };

  const getTab = () => {
    if (fiat === 'cny') {
      if (tab === 0) return 'bankCN';
      if (tab === 1) return 'alipay';
    }
    if (fiat === 'vnd') {
      if (tab === 0) return 'bankVN';
      if (tab === 1) return 'vietqr';
      if (tab === 2) return 'zalopay';
      if (tab === 3) return 'momo';
    }

    return 'bankCN';
  };

  return (
    <div className="bg-light-100 px-[15px] py-3 dark:bg-black-700">
      <div className="mx-0 flex flex-wrap items-center justify-between gap-[15px]">
        <SearchInput
          value={searchValue}
          setValue={setSearchValue}
          placeholder={t('trading.desc.accountNameorNumber')}
          className="w-[172px]"
        />
        <div className="flex gap-[15px] text-black-900 dark:text-white">
          <div
            className="flex cursor-pointer gap-[15px] rounded-[4px] border-[1px] border-solid border-light-500 px-[4px] py-[3px] dark:border-dark-500"
            onClick={onClickSorting('name')}
          >
            <div>{t('trading.desc.accountName')}</div>
            <img src={getSortingImage('name')} />
          </div>
          <div
            className="flex cursor-pointer gap-[15px] rounded-[4px] border-[1px] border-solid border-light-500 px-[4px] py-[3px] dark:border-dark-500"
            onClick={onClickSorting('bankName')}
          >
            <div>{t('trading.desc.bank')}</div>
            <img src={getSortingImage('bankName')} />
          </div>
        </div>
      </div>
      {!isBiggerThanMd && (
        <div className="my-[15px]">
          <Tabs labels={getTabArray()} defaultIndex={tab} onChange={(v) => setTab(v)} />
        </div>
      )}
      <RadioGroup name={name} onChange={(e) => setBankAccountId(e.target.value.split(',')[1])}>
        {isBiggerThanMd && (
          <PaymentTable
            name={name}
            accountArray={sortedBankArray}
            fiat={fiat}
            defaultSelected={defaultSelected}
          />
        )}
        {!isBiggerThanMd && (
          <PaymentCard
            name={name}
            accountArray={sortedBankArray}
            type={getTab()}
            defaultSelected={defaultSelected}
          />
        )}
      </RadioGroup>
    </div>
  );
};

export default PaymentMethodsList;
