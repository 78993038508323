import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useNavigate } from 'react-router-dom';
import Alert from 'src/component/Alert';
import Button from 'src/component/Button';
import NumberInput from 'src/component/NumberInput';
import { MediaQuery } from 'src/constant/Media';
import { Severity } from 'src/constant/Notification';
import { Page } from 'src/constant/Page';
import IcArrowDown from 'src/image/ic-arrow-down-s.svg';
import { BankAccount } from 'src/model/Bank';
import { Crypto, Fiat } from 'src/model/Coin';
import { OneTradingForm } from 'src/model/Form';
import { bn, bnFixed, bnFormat } from 'src/util/bigNumber';
import PaymentMethodsList from './PaymentMethodsList';

type Props = {
  idx: number;
  data: OneTradingForm;
  setData: (key: keyof OneTradingForm, value: string) => void;
  thisFiat?: string;
  fiat?: Fiat;
  coin?: Crypto;
  exchangeRate?: string;
  sufficient: boolean;
  onDelete: () => void;
  bankAccount?: BankAccount[];
  setHasError: (v: boolean) => void;
};

const TradingCard = ({
  idx,
  data,
  setData,
  thisFiat,
  fiat,
  coin,
  exchangeRate,
  sufficient,
  onDelete,
  bankAccount,
  setHasError,
}: Props) => {
  const { t } = useTranslation();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const navigate = useNavigate();
  const [expand, setExpand] = useState<boolean>(false);
  const { price, amount, minTotal, maxTotal, bankAccountId } = data;
  const thisBankAccount = useMemo(
    () => bankAccount?.find((v) => v.id === data.bankAccountId),
    [bankAccount, bankAccountId],
  );
  const amountInputHint = useMemo(() => {
    if (!coin) return '';

    const { minOrderAmount, maxOrderAmount } = coin;

    if (minOrderAmount && maxOrderAmount)
      return `${t('trading.desc.orderAmountHint')} ${bnFormat(
        minOrderAmount,
      )} ${coin.id.toUpperCase()} - ${bnFormat(maxOrderAmount)} ${coin.id?.toUpperCase()}`;
    else if (minOrderAmount && maxOrderAmount === null)
      return `${t('trading.desc.orderAmountHint')} ${bnFormat(
        minOrderAmount,
      )} ${coin.id.toUpperCase()} -`;
    else if (minOrderAmount === null && maxOrderAmount)
      return `${t('trading.desc.orderAmountHint')} 0 - ${bnFormat(
        maxOrderAmount,
      )} ${coin.id.toUpperCase()}`;
  }, [coin, t]);

  const priceError = useMemo(() => {
    if (price === '') return;
    const diff = bn(price)
      .minus(exchangeRate ?? '')
      .div(exchangeRate ?? '');
    if (bn(price).gt(exchangeRate ?? '')) return t('trading.desc.gtRefrencePrice');
    else if (diff.lt(-0.2)) return t('trading.desc.diffReferencePrice');
  }, [price, exchangeRate, t]);

  const amountError = useMemo(() => {
    if (amount === '') return;
    const minOrderAmount = coin?.minOrderAmount;
    const maxOrderAmount = coin?.maxOrderAmount;

    if (!sufficient) return t('trading.desc.insufficientBalance');
    else if (minOrderAmount && bn(amount).lt(minOrderAmount))
      return t('trading.desc.lessThanMinOrderAmount');
    else if (maxOrderAmount && bn(amount).gt(maxOrderAmount))
      return t('trading.desc.greaterThanMinOrderAmount');
  }, [amount, sufficient, t]);

  const minTotalError = useMemo(() => {
    if (price === '' || amount === '' || minTotal === '') return;
    const minOrderTotal = fiat?.minOrderTotal;

    if (bn(price).times(amount).lt(minTotal)) return t('trading.desc.exceedTotal');
    else if (minOrderTotal && bn(minTotal).lt(minOrderTotal))
      return t('trading.desc.lessThanSystem');
  }, [price, amount, minTotal, t]);

  const maxTotalError = useMemo(() => {
    if (price === '' || amount === '' || minTotal === '' || maxTotal === '') return;
    const maxOrderTotal = fiat?.maxOrderTotal;

    if (bn(minTotal).gt(maxTotal)) return t('trading.desc.lessThanMin');
    else if (maxOrderTotal && bn(maxTotal).gt(maxOrderTotal))
      return t('trading.desc.greaterThanSystem');
  }, [price, amount, minTotal, maxTotal, t]);

  useEffect(() => {
    if (priceError || amountError || minTotalError || maxTotalError) setHasError(true);
    else setHasError(false);
  }, [priceError, amountError, minTotalError, maxTotalError]);

  return (
    <div className="rounded-xl bg-white px-[15px] py-[25px] dark:bg-black-800">
      <div className="flex flex-wrap gap-[30px] md:gap-[40px]">
        <div className="relative w-[calc(50%-15px)] xs:w-[calc(100%/3-20px)] md:w-[calc(20%-32px)]">
          <NumberInput
            label={t('trading.desc.unitPrice')}
            decimal={fiat?.priceDecimal ?? 0}
            asterisked
            value={price}
            onChange={(e) => setData('price', e.target.value)}
            error={priceError}
          />
          <Button
            appearance="text"
            className="absolute right-[15px] top-0"
            type="button"
            onClick={() => setData('price', bnFixed(exchangeRate ?? ''))}
          >
            {t('trading.act.apply')}
          </Button>
        </div>
        <div className="relative w-[calc(50%-15px)] xs:w-[calc(100%/3-20px)] md:w-[calc(20%-32px)]">
          <NumberInput
            label={t('trading.desc.amount')}
            decimal={coin?.decimal ?? 0}
            asterisked
            hint={amountInputHint}
            value={amount}
            onChange={(e) => setData('amount', e.target.value)}
            error={amountError}
          />
        </div>
        <div className="w-full xs:w-[calc(100%/3-20px)] md:w-[calc(20%-32px)]">
          <div className="flex items-center justify-between xs:flex-col xs:items-start xs:justify-normal xs:gap-[10px]">
            <div className=" text-[14px] text-dark-500 dark:text-grey-300">
              {t('trading.desc.receive')}
            </div>
            <div className="flex w-full gap-2 xs:justify-between">
              <div>
                {price && amount && fiat
                  ? bn(price).times(amount).dp(fiat.decimal, 1).toFormat()
                  : '-'}
              </div>
              <div>{fiat?.id.toUpperCase()}</div>
            </div>
          </div>
        </div>
        <div className="relative w-[calc(50%-15px)] xs:w-[calc(100%/3-20px)] md:w-[calc(20%-32px)]">
          <NumberInput
            label={t('trading.desc.minimalLimit')}
            hint={t('trading.desc.minLimit', {
              default: fiat?.minOrderTotal ? bnFormat(fiat.minOrderTotal) : '-',
              currency: fiat?.id.toUpperCase(),
            })}
            decimal={fiat?.decimal ?? 0}
            asterisked
            value={minTotal}
            onChange={(e) => setData('minTotal', e.target.value)}
            error={minTotalError}
          />
          <span className="absolute right-[15px] top-[30px] text-grey-700 dark:text-grey-300 xs:right-[15px]">
            {fiat?.id.toUpperCase()}
          </span>
        </div>
        <div className="relative w-[calc(50%-15px)] xs:w-[calc(100%/3-20px)] md:w-[calc(20%-32px)]">
          <NumberInput
            label={t('trading.desc.maximalLimit')}
            hint={t('trading.desc.maxLimit', {
              default: fiat?.maxOrderTotal ? bnFormat(fiat.maxOrderTotal) : '-',
              currency: fiat?.id.toUpperCase(),
            })}
            decimal={fiat?.decimal ?? 0}
            asterisked
            value={maxTotal}
            onChange={(e) => setData('maxTotal', e.target.value)}
            error={maxTotalError}
          />
          <Button
            appearance="text"
            className="absolute right-0 top-0"
            type="button"
            onClick={() =>
              setData(
                'maxTotal',
                bn(amount ?? 0)
                  .times(price ?? 0)
                  .toFixed(fiat?.decimal ?? 0, 3),
              )
            }
          >
            {t('trading.act.max')}
          </Button>
          <span className="absolute right-0 top-[30px] text-grey-700 dark:text-grey-300 xs:right-[15px]">
            {fiat?.id.toUpperCase()}
          </span>
        </div>
      </div>
      <div className="mt-[25px] flex items-center justify-between py-2">
        <div className="text-dark-500 dark:text-grey-300">{t('trading.desc.receivePayment')}</div>
        <div className="flex cursor-pointer items-center" onClick={() => setExpand(!expand)}>
          <div className="text-[14px] font-bold text-turquoise dark:text-aqua">
            {t('tradingBatch.act.select')}
          </div>
          <img src={IcArrowDown} />
        </div>
      </div>
      {bankAccount !== undefined && bankAccount.length > 0 ? (
        <div
          className={classNames('overflow-y-hidden transition-all duration-[0.5s]', {
            '!max-h-0': !expand,
          })}
          style={{
            maxHeight: `${
              130 +
              (isBiggerThanMd
                ? 189 * (bankAccount?.length ?? 0 + 1)
                : 140 * (bankAccount?.length ?? 0))
            }px`,
          }}
        >
          <PaymentMethodsList
            name={idx.toString()}
            bankArray={bankAccount}
            defaultSelected={bankAccountId}
            fiat={thisFiat}
            setBankAccountId={(id: string) => {
              setData('bankAccountId', id);
              setExpand(false);
            }}
          />
        </div>
      ) : (
        <Alert severity={Severity.Warning} className="mt-[30px]">
          <Trans
            i18nKey="trading.desc.noPaymentMethodNotice"
            values={{ link: t('trading.act.account') }}
            components={[
              <Button key={0} appearance="text" onClick={() => navigate(Page.Account)} />,
            ]}
          />
        </Alert>
      )}
      {data.bankAccountId.length > 0 &&
        !expand &&
        (isBiggerThanMd ? (
          <div>
            {thisBankAccount?.paymentMethodType === 'bank' &&
              thisBankAccount?.nationality === 'CN' && (
                <>
                  <div className="flex flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
                    <div className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('trading.desc.accountName')}
                    </div>
                    <div className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('trading.desc.bank')}
                    </div>
                    <div className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('trading.desc.bankAccount')}
                    </div>
                    <div className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('trading.desc.bankProvinceName')}
                    </div>
                    <div className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('trading.desc.bankCityName')}
                    </div>
                    <div className="box-border w-1/6 px-[12px] py-[16px]">
                      {t('trading.desc.bankBranchName')}
                    </div>
                  </div>
                  <div className="flex flex-row text-[14px]">
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {thisBankAccount?.name}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {thisBankAccount?.bankName}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {thisBankAccount?.account}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {thisBankAccount?.province}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {thisBankAccount?.city}
                    </div>
                    <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                      {thisBankAccount?.bankBranchName}
                    </div>
                  </div>
                </>
              )}
            {thisBankAccount?.paymentMethodType === 'bank' &&
              thisBankAccount?.nationality === 'VN' && (
                <>
                  <div className="flex flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
                    <div className="box-border w-2/6 px-[12px] py-[16px]">
                      {t('trading.desc.accountName')}
                    </div>
                    <div className="box-border w-2/6 px-[12px] py-[16px]">
                      {t('trading.desc.bank')}
                    </div>
                    <div className="box-border w-2/6 px-[12px] py-[16px]">
                      {t('trading.desc.bankAccount')}
                    </div>
                  </div>
                  <div className="flex flex-row text-[14px]">
                    <div className="box-border w-2/6 break-all md:px-[12px] md:py-0">
                      {thisBankAccount?.name}
                    </div>
                    <div className="box-border w-2/6 break-all md:px-[12px] md:py-0">
                      {thisBankAccount?.bankName}
                    </div>
                    <div className="box-border w-2/6 break-all md:px-[12px] md:py-0">
                      {thisBankAccount?.account}
                    </div>
                  </div>
                </>
              )}
            {thisBankAccount?.paymentMethodType === 'alipay' && (
              <>
                <div className="flex flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
                  <div className="box-border w-1/6 px-[12px] py-[16px]">
                    {t('trading.desc.alipayAccountName')}
                  </div>
                  <div className="box-border w-1/6 px-[12px] py-[16px]">
                    {t('trading.desc.alipayAccount')}
                  </div>
                  <div className="box-border w-4/6 px-[12px] py-[16px]">
                    {t('trading.desc.alipayQRCode')}
                  </div>
                </div>
                <div className="flex flex-row text-[14px]">
                  <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                    {thisBankAccount?.name}
                  </div>
                  <div className="box-border w-1/6 break-all md:px-[12px] md:py-0">
                    {thisBankAccount?.account}
                  </div>
                  <div className="box-border w-4/6 break-all md:px-[12px] md:py-0">
                    {thisBankAccount?.imageUrl
                      ? t('trading.desc.alipayQRCodeUploaded')
                      : t('trading.desc.alipayQRCodeUnuploaded')}
                  </div>
                </div>
              </>
            )}
            {thisBankAccount?.paymentMethodType === 'vietqr' && (
              <>
                <div className="flex flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
                  <div className="box-border w-2/6 px-[12px] py-[16px]">
                    {t('trading.desc.alipayAccountName')}
                  </div>
                  <div className="box-border w-4/6 px-[12px] py-[16px]">
                    {t('trading.desc.alipayQRCode')}
                  </div>
                </div>
                <div className="flex flex-row text-[14px]">
                  <div className="box-border w-2/6 break-all md:px-[12px] md:py-0">
                    {thisBankAccount?.name}
                  </div>
                  <div className="box-border w-4/6 break-all md:px-[12px] md:py-0">
                    {thisBankAccount?.imageUrl
                      ? t('trading.desc.alipayQRCodeUploaded')
                      : t('trading.desc.alipayQRCodeUnuploaded')}
                  </div>
                </div>
              </>
            )}
            {thisBankAccount?.paymentMethodType === 'zalopay' && (
              <>
                <div className="flex flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
                  <div className="box-border w-2/6 px-[12px] py-[16px]">
                    {t('bankAccount.desc.account')}
                  </div>
                  <div className="box-border w-4/6 px-[12px] py-[16px]">
                    {t('trading.desc.alipayQRCode')}
                  </div>
                </div>
                <div className="flex flex-row text-[14px]">
                  <div className="box-border w-2/6 break-all md:px-[12px] md:py-0">
                    {thisBankAccount?.account}
                  </div>
                  <div className="box-border w-4/6 break-all md:px-[12px] md:py-0">
                    {thisBankAccount?.imageUrl
                      ? t('trading.desc.alipayQRCodeUploaded')
                      : t('trading.desc.alipayQRCodeUnuploaded')}
                  </div>
                </div>
              </>
            )}
            {thisBankAccount?.paymentMethodType === 'momo' && (
              <>
                <div className="flex flex-row text-[14px] font-bold text-grey-700 dark:text-grey-300">
                  <div className="box-border w-2/6 px-[12px] py-[16px]">
                    {t('bankAccount.desc.account')}
                  </div>
                  <div className="box-border w-4/6 px-[12px] py-[16px]">
                    {t('trading.desc.alipayQRCode')}
                  </div>
                </div>
                <div className="flex flex-row text-[14px]">
                  <div className="box-border w-2/6 break-all md:px-[12px] md:py-0">
                    {thisBankAccount?.account}
                  </div>
                  <div className="box-border w-4/6 break-all md:px-[12px] md:py-0">
                    {thisBankAccount?.imageUrl
                      ? t('trading.desc.alipayQRCodeUploaded')
                      : t('trading.desc.alipayQRCodeUnuploaded')}
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="px-[15px] py-[10px] text-[14px] leading-[21px]">
            {thisBankAccount?.paymentMethodType === 'bank' &&
              thisBankAccount?.nationality === 'CN' && (
                <>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.accountName')}
                    </div>
                    <div>{thisBankAccount?.name}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.bank')}
                    </div>
                    <div>{thisBankAccount?.bankName}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.bankAccount')}
                    </div>
                    <div>{thisBankAccount?.account}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.bankProvinceName')}/{t('trading.desc.bankCityName')}
                    </div>
                    <div>
                      {thisBankAccount?.province}/{thisBankAccount?.city}
                    </div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.bankBranchName')}
                    </div>
                    <div>{thisBankAccount?.bankBranchName}</div>
                  </div>
                </>
              )}
            {thisBankAccount?.paymentMethodType === 'bank' &&
              thisBankAccount?.nationality === 'VN' && (
                <>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.accountName')}
                    </div>
                    <div>{thisBankAccount?.name}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.bank')}
                    </div>
                    <div>{thisBankAccount?.bankName}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.bankAccount')}
                    </div>
                    <div>{thisBankAccount?.account}</div>
                  </div>
                </>
              )}
            {thisBankAccount?.paymentMethodType === 'alipay' && (
              <>
                <div className="flex gap-[15px]">
                  <div className="font-bold text-grey-700 dark:text-grey-300">
                    {t('trading.desc.alipayAccountName')}
                  </div>
                  <div>{thisBankAccount?.name}</div>
                </div>
                <div className="flex gap-[15px]">
                  <div className="font-bold text-grey-700 dark:text-grey-300">
                    {t('trading.desc.alipayAccount')}
                  </div>
                  <div>{thisBankAccount?.account}</div>
                </div>
                <div className="flex gap-[15px]">
                  <div className="font-bold text-grey-700 dark:text-grey-300">
                    {t('trading.desc.alipayQRCode')}
                  </div>
                  <div>
                    {thisBankAccount?.imageUrl
                      ? t('trading.desc.alipayQRCodeUploaded')
                      : t('trading.desc.alipayQRCodeUnuploaded')}
                  </div>
                </div>
              </>
            )}
            {thisBankAccount?.paymentMethodType === 'vietqr' && (
              <>
                <div className="flex gap-[15px]">
                  <div className="font-bold text-grey-700 dark:text-grey-300">
                    {t('trading.desc.alipayAccountName')}
                  </div>
                  <div>{thisBankAccount?.name}</div>
                </div>

                <div className="flex gap-[15px]">
                  <div className="font-bold text-grey-700 dark:text-grey-300">
                    {t('trading.desc.alipayQRCode')}
                  </div>
                  <div>
                    {thisBankAccount?.imageUrl
                      ? t('trading.desc.alipayQRCodeUploaded')
                      : t('trading.desc.alipayQRCodeUnuploaded')}
                  </div>
                </div>
              </>
            )}
            {thisBankAccount?.paymentMethodType === 'zalopay' && (
              <>
                <div className="flex gap-[15px]">
                  <div className="font-bold text-grey-700 dark:text-grey-300">
                    {t('bankAccount.desc.account')}
                  </div>
                  <div>{thisBankAccount?.account}</div>
                </div>
                <div className="flex gap-[15px]">
                  <div className="font-bold text-grey-700 dark:text-grey-300">
                    {t('trading.desc.alipayQRCode')}
                  </div>
                  <div>
                    {thisBankAccount?.imageUrl
                      ? t('trading.desc.alipayQRCodeUploaded')
                      : t('trading.desc.alipayQRCodeUnuploaded')}
                  </div>
                </div>
              </>
            )}
            {thisBankAccount?.paymentMethodType === 'momo' && (
              <>
                <div className="flex gap-[15px]">
                  <div className="font-bold text-grey-700 dark:text-grey-300">
                    {t('bankAccount.desc.account')}
                  </div>
                  <div>{thisBankAccount?.account}</div>
                </div>
                <div className="flex gap-[15px]">
                  <div className="font-bold text-grey-700 dark:text-grey-300">
                    {t('trading.desc.alipayQRCode')}
                  </div>
                  <div>
                    {thisBankAccount?.imageUrl
                      ? t('trading.desc.alipayQRCodeUploaded')
                      : t('trading.desc.alipayQRCodeUnuploaded')}
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      <div className="mt-[15px] xs:text-right">
        <Button
          appearance="text"
          type="button"
          className="text-crimson dark:text-red"
          onClick={onDelete}
        >
          {t('tradingBatch.act.delete')}
        </Button>
      </div>
    </div>
  );
};

export default TradingCard;
