import userEndpoint from 'src/api/userEndpoint';
import { CommissionForm } from 'src/model/Form';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';

export const getKids = async () => {
  try {
    dispatch(startWaiting());
    const res = await userEndpoint.getUsersKids();

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const editCommission = async (id: string, data: CommissionForm) => {
  try {
    dispatch(startWaiting());
    await userEndpoint.putUser(id, {
      commissionDeltas: {
        cny: Number(data.cny),
        vnd: Number(data.vnd),
      },
    });
  } finally {
    dispatch(finishWaiting());
  }
};
