import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Radio from 'src/component/Radio';
import { BankAccount } from 'src/model/Bank';

type Props = {
  accountArray: BankAccount[];
  defaultIndex?: number;
  type: string;
};

const PaymentCard = ({ accountArray, defaultIndex, type }: Props) => {
  const { t } = useTranslation();

  const filteredBankAccount = useMemo(() => {
    if (type === 'bankCN')
      return accountArray.filter((v) => v.paymentMethodType === 'bank' && v.nationality === 'CN');
    if (type === 'bankVN')
      return accountArray.filter((v) => v.paymentMethodType === 'bank' && v.nationality === 'VN');
    if (type === 'alipay') return accountArray.filter((v) => v.paymentMethodType === 'alipay');
    if (type === 'vietqr') return accountArray.filter((v) => v.paymentMethodType === 'vietqr');
    if (type === 'zalopay') return accountArray.filter((v) => v.paymentMethodType === 'zalopay');
    if (type === 'momo') return accountArray.filter((v) => v.paymentMethodType === 'momo');

    return accountArray;
  }, [type, accountArray]);

  return (
    <div>
      {filteredBankAccount.map((v, index) => (
        <div key={v.id}>
          <div className="flex px-0 py-[16px]">
            <div className="mr-[8px] mt-[1px] flex flex-row items-start justify-center pt-[3px] md:m-0 md:w-[40px] md:items-center md:pt-0">
              <Radio value={v.id} defaultChecked={index === defaultIndex} />
            </div>
            <label htmlFor={v.id} className="text-[14px] leading-[21px]">
              {v.paymentMethodType === 'bank' && v.nationality === 'CN' && (
                <>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.accountName')}
                    </div>
                    <div>{v.name}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.bank')}
                    </div>
                    <div>{v.bankName}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.bankAccount')}
                    </div>
                    <div>{v.account}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.bankProvinceCity')}
                    </div>
                    <div>
                      {v.province}/{v.city}
                    </div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.bankBranchName')}
                    </div>
                    <div>{v.bankBranchName}</div>
                  </div>
                </>
              )}
              {v.paymentMethodType === 'bank' && v.nationality === 'VN' && (
                <>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('bankAccount.desc.accountName')}
                    </div>
                    <div>{v.name}</div>
                  </div>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('bankAccount.desc.bankInformation')}
                    </div>
                    <div>{v.bankName}</div>
                  </div>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('bankAccount.desc.accountId')}
                    </div>
                    <div>{v.account}</div>
                  </div>
                </>
              )}
              {v.paymentMethodType === 'alipay' && (
                <>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.alipayAccountName')}
                    </div>
                    <div>{v.name}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('trading.desc.alipayAccount')}
                    </div>
                    <div>{v.account}</div>
                  </div>
                  <div className="flex gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {v.imageUrl
                        ? t('trading.desc.alipayQRCodeUploaded')
                        : t('trading.desc.alipayQRCodeUnuploaded')}
                    </div>
                  </div>
                </>
              )}

              {v.paymentMethodType === 'vietqr' && (
                <>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('bankAccount.desc.alipayAccountName')}
                    </div>
                    <div>{v.name}</div>
                  </div>
                  {v.imageUrl ? (
                    <div className={'flex flex-row  items-center'}>
                      <div> {t('bankAccount.desc.alipayQRCodeUploaded')}</div>
                    </div>
                  ) : (
                    <div>{t('bankAccount.desc.alipayQRCodeUnuploaded')}</div>
                  )}
                </>
              )}
              {v.paymentMethodType === 'zalopay' && (
                <>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('bankAccount.desc.account')}
                    </div>
                    <div>{v.account}</div>
                  </div>
                  {v.imageUrl ? (
                    <div className={'flex flex-row  items-center'}>
                      <div> {t('bankAccount.desc.alipayQRCodeUploaded')}</div>
                    </div>
                  ) : (
                    <div>{t('bankAccount.desc.alipayQRCodeUnuploaded')}</div>
                  )}
                </>
              )}
              {v.paymentMethodType === 'momo' && (
                <>
                  <div className="flex items-center gap-[15px]">
                    <div className="font-bold text-grey-700 dark:text-grey-300">
                      {t('bankAccount.desc.account')}
                    </div>
                    <div>{v.account}</div>
                  </div>
                  {v.imageUrl ? (
                    <div className={'flex flex-row  items-center'}>
                      <div> {t('bankAccount.desc.alipayQRCodeUploaded')}</div>
                    </div>
                  ) : (
                    <div>{t('bankAccount.desc.alipayQRCodeUnuploaded')}</div>
                  )}
                </>
              )}
            </label>
          </div>
          <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        </div>
      ))}
    </div>
  );
};

export default PaymentCard;
